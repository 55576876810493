import styled from "styled-components";

import theme from "../../../config/theme";

export const HeroContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const HeroImage = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background-image: ${(props) => (props.image ? `url(${props.image})` : "")};
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
`;

export const HeroTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  width: 60%;
  max-width: 1000px;
  color: ${(props) => (props.light ? theme.light : theme.dark)};

  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`;

export const TagLine = styled.p`
  color: ${(props) => (props.light ? theme.light : theme.dark)};
  font-size: 1.5rem;
  text-align: center;
`;
