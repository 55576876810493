import React from "react";
import PageHeader from "../PageHeader";

import { Container, Value, Tag, Divider, Info } from "./Styles/Values";

function Values() {
  return (
    <Container>
      <PageHeader title="Our Mission" />
      <Info style={{ marginTop: "1rem" }}>
        There are countless companies who claim they are changing the way that
        processing is done. Here at Swipe Save we have a very clear idea of what
        matters most and we strive everyday to work towards creating an even
        playing field for merchants. The days of worrying about your rates are
        over.
      </Info>
      <Value>Honesty,</Value>
      <Tag>when providing the best rates.</Tag>
      <Divider />
      <Value>Integrity,</Value>
      <Tag>in following through with our promises.</Tag>
      <Divider />
      <Value>Transparency,</Value>
      <Tag>with our business and our pricing.</Tag>
      <Divider />
      <Value>Loyalty,</Value>
      <Tag>to our customers, the people we rely on.</Tag>
    </Container>
  );
}

export default Values;
