import React, { useState } from "react";

import {
  ResidualDisplay,
  SummaryTable,
  SummaryHeader,
  SummaryRow,
  SummaryData,
  SummaryBody,
  SummaryHead,
  SectionTitle,
} from "../Styles/AgentDashboard";

import fire from "../../../helpers/firebase";
import { AnimatedOpacity } from "../../../helpers/reusables";

function Residual({ profile }) {
  const [paymentLog, setPaymentLog] = useState([]);
  const [merchList, setMerchList] = useState([]);

  async function populateTables(uid) {
    let db = fire.firestore();
    const paymentsnapshot = await db
      .collection("users")
      .doc(uid)
      .collection("paymentLog")
      .get();
    const merchsnapchot = await db
      .collection("users")
      .doc(uid)
      .collection("merchantList")
      .get();

    if (paymentLog.length < 1) {
      let paymentArray = [];
      paymentsnapshot.docs.map((doc) => paymentArray.push(doc.data()));
      setPaymentLog(paymentArray);
    }
    if (merchList.length < 1) {
      let merchArray = [];
      merchsnapchot.docs.map((doc) => merchArray.push(doc.data()));
      setMerchList(merchArray);
    }
  }
  populateTables(fire.auth().currentUser.uid);

  return (
    <AnimatedOpacity delay={0.2}>
      <ResidualDisplay>
        <SectionTitle>Payment Summary</SectionTitle>
        <SummaryTable>
          <SummaryHead>
            <SummaryRow>
              <SummaryHeader>Book</SummaryHeader>
              <SummaryHeader>Payment Amount</SummaryHeader>
              <SummaryHeader>Payment Date</SummaryHeader>
            </SummaryRow>
          </SummaryHead>
          <SummaryBody>
            {paymentLog && paymentLog.length >= 1 ? (
              paymentLog.map((payment, i) => {
                return (
                  <SummaryRow key={i}>
                    <SummaryData>{payment.payment.book}</SummaryData>
                    <SummaryData>${payment.payment.amount}</SummaryData>
                    <SummaryData>{payment.payment.date}</SummaryData>
                  </SummaryRow>
                );
              })
            ) : (
              <SummaryRow>
                <SummaryData>No Data</SummaryData>
                <SummaryData>No Data</SummaryData>
                <SummaryData>No Data</SummaryData>
              </SummaryRow>
            )}
          </SummaryBody>
        </SummaryTable>
        <SectionTitle>Merchants</SectionTitle>
        <SummaryTable>
          <SummaryHead>
            <SummaryRow>
              <SummaryHeader>Merchant</SummaryHeader>
              <SummaryHeader>Book</SummaryHeader>
              <SummaryHeader>Approval Date</SummaryHeader>
              <SummaryHeader>Rep #</SummaryHeader>
            </SummaryRow>
          </SummaryHead>
          <SummaryBody>
            {merchList && merchList.length > 1 ? (
              merchList.map((merchant, i) => {
                return (
                  <SummaryRow key={i}>
                    <SummaryData>{merchant.merchant.name}</SummaryData>
                    <SummaryData>{merchant.merchant.book}</SummaryData>
                    <SummaryData>{merchant.merchant.approval}</SummaryData>
                    <SummaryData>{profile.repNum}</SummaryData>
                  </SummaryRow>
                );
              })
            ) : (
              <SummaryRow>
                <SummaryData>No Data</SummaryData>
                <SummaryData>No Data</SummaryData>
                <SummaryData>No Data</SummaryData>
                <SummaryData>No Data</SummaryData>
              </SummaryRow>
            )}
          </SummaryBody>
        </SummaryTable>
      </ResidualDisplay>
    </AnimatedOpacity>
  );
}

export default Residual;
