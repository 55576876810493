import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyBQt5LOqNKLTgq4pWqXHNzMcmS0MVvAohA",
  authDomain: "swipesave-eeb4e.firebaseapp.com",
  projectId: "swipesave-eeb4e",
  storageBucket: "swipesave-eeb4e.appspot.com",
  messagingSenderId: "862083009732",
  appId: "1:862083009732:web:5823d0b65fe10812becd91",
  measurementId: "G-5CCRRYVGEC",
};

const fire = firebase.initializeApp(config);

export default fire;
