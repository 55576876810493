import styled from "styled-components";

import theme from "../../../config/theme";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${theme.secondary};
  padding: 2rem 0;
`;

export const Title = styled.h2`
  font-size: 3rem;
  color: ${theme.light};
  text-align: center;
  width: 95%;
  max-width: 750px;
  margin: 0 auto;
`;

export const List = styled.ul`
  padding-left: 0;
  list-style-type: none;
  width: 95%;
  margin: 0 auto;
`;

export const Item = styled.li`
  margin: 1.5rem 0;
  text-align: center;

  color: ${theme.light};
  font-size: 1.5rem;
`;

export const Accent = styled.span`
  text-decoration: underline;
`;
