import styled from "styled-components";

import theme from "../../../config/theme";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
`;

export const FaqSection = styled.div`
  background-color: ${theme.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 600px;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 40px;

  &:hover {
    cursor: pointer;
  }
`;

export const Question = styled.p`
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
`;

export const Answer = styled.p`
  font-size: 1.2rem;
  color: ${theme.dark};
`;
