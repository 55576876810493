import React from "react";

import Footer from "../Footer";
import Header from "../Header";
import PageHeader from "../PageHeader";
import ContactForm from "../ContactForm";
import { AnimatedOpacity } from "../../helpers/reusables";

function Contact() {
  return (
    <>
      <Header />
      <AnimatedOpacity>
        <ContactForm />
      </AnimatedOpacity>
      <Footer />
    </>
  );
}

export default Contact;
