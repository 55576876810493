import React, { useState } from "react";

import {
  ContentContainer,
  DashNavigation,
  Nav,
  NavItem,
  PageContainer,
  Submit,
} from "./Styles/AgentDashboard";

import DisplayControl from "./components/DisplayControl";
import Login from "./components/Login";

import fire from "../../helpers/firebase";

import { AiFillHome } from "react-icons/ai";
import { FaMoneyBill } from "react-icons/fa";
import { RiFilePaperLine, RiAdminFill } from "react-icons/ri";
import { BiCoinStack } from "react-icons/bi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";

import "./Styles/AgentDashboard.css";
import { getEmployeeProfile } from "../../helpers/helpers";

import { AnimatedOpacity } from "../../helpers/reusables";

import logo from "../../images/logo-long.png";

import styles from "./Styles/agents.module.css";

function AgentDashboard() {
  const [screen, setScreen] = useState("home");
  const [loggedIn, setLoggedIn] = useState(false);
  const [profile, setProfile] = useState({});

  if (JSON.stringify(profile) === "{}" && fire.auth().currentUser) {
    getEmployeeProfile(fire.auth().currentUser.uid).then((res) => {
      setProfile(res);
    });
  }

  const handleLogout = () => {
    fire.auth().signOut();
    setLoggedIn(false);
    localStorage.setItem("uid", "");
  };

  // if (fire.auth().currentUser && !profile) {
  //   setLoggedIn(true)
  // }

  return (
    <AnimatedOpacity>
      {loggedIn ? (
        <div style={{ height: "100vh", overflowY: "hidden" }}>
          <div className={styles.dashboardHeader}>
            <img
              className={styles.logoImage}
              src={logo}
              alt="Digging up savings one statement at a time"
            />
            <div className={styles.rightGroup}>
              <Submit onClick={() => handleLogout()}>Logout</Submit>
              <CgProfile size={40} className={styles.profileIcon} />
            </div>
          </div>
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f5f5f5",
            }}
          >
            <DashNavigation>
              <Nav>
                <NavItem
                  onClick={() => setScreen("home")}
                  active={screen === "home"}
                >
                  <AiFillHome className="dash-icon" />
                  Home
                </NavItem>
                <NavItem
                  onClick={() => setScreen("residual")}
                  active={screen === "residual"}
                >
                  <FaMoneyBill className="dash-icon" />
                  Residual
                </NavItem>
                <NavItem
                  onClick={() => setScreen("resources")}
                  active={screen === "resources"}
                >
                  <BiCoinStack className="dash-icon" />
                  Resources
                </NavItem>
                <NavItem
                  onClick={() => setScreen("order")}
                  active={screen === "order"}
                >
                  <RiFilePaperLine className="dash-icon" />
                  Supplies
                </NavItem>
                <NavItem
                  onClick={() => setScreen("crm")}
                  active={screen === "crm"}
                >
                  <BsFillPersonLinesFill className="dash-icon" />
                  Leads
                </NavItem>
                {profile && profile.admin === true ? (
                  <NavItem
                    onClick={() => setScreen("admin")}
                    active={screen === "admin"}
                  >
                    <RiAdminFill className="dash-icon" />
                    Admin
                  </NavItem>
                ) : null}
              </Nav>
            </DashNavigation>
            <ContentContainer>
              <DisplayControl
                handleLogout={handleLogout}
                screen={screen}
                profile={profile}
              />
            </ContentContainer>
          </div>
        </div>
      ) : (
        <PageContainer>
          <Login setLoggedIn={setLoggedIn} />
        </PageContainer>
      )}
    </AnimatedOpacity>
  );
}

export default AgentDashboard;
