import React from "react";
import Adduser from "./AdminScreens/Adduser";
import NewMerchant from "./AdminScreens/NewMerchant";
import Recordpayment from "./AdminScreens/Recordpayment";

function AdminControl({ screen, ...restProps }) {
  switch (screen) {
    case "adduser":
      return <Adduser {...restProps} />;
    case "recordpayment":
      return <Recordpayment {...restProps} />;
    case "newmerchant":
      return <NewMerchant {...restProps} />;
    default:
      return <Adduser {...restProps} />;
  }
}

export default AdminControl;
