import React from "react";

import {
  FooterContainer,
  Contact,
  Schedule,
  Social,
  Title,
  Text,
  InfoContainer,
  Copyright,
  EmailLink,
} from "./Styles/Footer";

import { AiFillPhone, AiFillMail, AiFillInstagram } from "react-icons/ai";

import { FaFacebookSquare } from "react-icons/fa";

function Footer() {
  return (
    <>
      <FooterContainer>
        <Contact>
          <Title>Contact Us</Title>
          <Text>Ready to start saving?</Text>
          <InfoContainer>
            <AiFillPhone />
            <Text>(609) 369 2697</Text>
          </InfoContainer>
          <InfoContainer>
            <AiFillMail />
            <EmailLink href="mailto:jaice@swipesaveconsulting.com">
              Email Us
            </EmailLink>
          </InfoContainer>
        </Contact>
        <Schedule>
          <Title>Hours of Operation</Title>
          <Text>
            <strong>Monday – Friday</strong>
            <br />
            9:00AM EST – 6:00PM EST
          </Text>
          <Text>
            <strong>Saturday</strong>
            <br />
            9:00AM EST – 5:00PM EST
          </Text>
          <Text>
            <strong>Sunday</strong>
            <br />
            Closed
          </Text>
        </Schedule>
        <Social>
          <Title>Check us Out</Title>
          <EmailLink
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/swipesaveconsulting"
          >
            <FaFacebookSquare
              style={{ fontSize: "3rem", marginBottom: "1rem" }}
            />
          </EmailLink>
          <EmailLink target="_blank" rel="noreferrer" href="">
            <AiFillInstagram style={{ fontSize: "3rem" }} />
          </EmailLink>
        </Social>
      </FooterContainer>
      <Copyright>
        &#169; Swipe Save Consulting LLC. {new Date().getFullYear()}
      </Copyright>
    </>
  );
}

export default Footer;
