import styled from "styled-components";

import theme from "../../../config/theme";

export const FooterContainer = styled.footer`
  background-color: ${theme.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  border-top: 2px solid ${theme.light};
  border-bottom: 2px solid ${theme.light};

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 30%;
  color: ${theme.light};

  @media screen and (max-width: 720px) {
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.light};
    width: 60%;
    margin: 0 auto;
  }
`;

export const Contact = styled(Column)``;

export const Schedule = styled(Column)``;

export const Social = styled(Column)`
  & svg:hover {
    color: ${theme.hover};
    cursor: pointer;
  }
`;

export const Title = styled.h2``;

export const Text = styled.p`
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Copyright = styled.p`
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  background-color: ${theme.secondary};
  padding: 2rem 0;
  margin: 0;
  color: ${theme.light};
`;

export const EmailLink = styled.a`
  text-decoration: none;
  color: white;

  &:hover {
    cursor: pointer;
  }
`;
