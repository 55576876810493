import React, { useState } from "react";

import {
  AdminForm,
  AdminInput,
  AdminLabel,
  AdminSelect,
  Submit,
} from "../../../Styles/AgentDashboard";

import { useForm } from "react-hook-form";

import fire from "../../../../../helpers/firebase";
import {
  formatDate,
  formatMoney,
  bookOptions,
} from "../../../../../helpers/helpers";

function Recordpayment() {
  const { register, handleSubmit, watch, errors } = useForm();
  const [payAmount, setPayAmount] = useState("");
  const [bookName, setBookName] = useState("");
  const [payDate, setPayDate] = useState("");
  const [agentID, setAgentID] = useState("");
  const [agentNames, setAgentNames] = useState([]);

  let db = fire.firestore();

  const clearInputs = () => {
    setPayAmount("");
    setBookName("");
    setPayDate("");
  };

  const handlePayment = (data) => {
    let payment = {
      date: data.payDate,
      amount: data.payAmount,
      book: data.bookName,
    };
    let payRef = db
      .collection("users")
      .doc(data.agentID)
      .collection("paymentLog")
      .doc(data.payDate);
    clearInputs();
    payRef.set({ payment });
  };

  async function getAgentNames() {
    const snapshot = await db.collection("users").get();
    let nameArray = [];
    snapshot.docs.map((doc) => {
      let name = doc.data().firstName + " " + doc.data().lastName;
      let id = doc.id;
      nameArray.push({
        name,
        id,
      });
    });
    setAgentNames(nameArray);
  }

  if (agentNames.length === 0) {
    getAgentNames();
  }

  return (
    <AdminForm onSubmit={handleSubmit(handlePayment)}>
      <AdminLabel htmlFor="agentID">
        Agent
        <AdminSelect
          value={agentID}
          onChange={(e) => setAgentID(e.target.value)}
          id="agentID"
          name="agentID"
          ref={register}
        >
          {agentNames && agentNames.length > 0
            ? agentNames.map((agent, i) => {
                return (
                  <option key={i} value={agent.id}>
                    {agent.name}
                  </option>
                );
              })
            : null}
        </AdminSelect>
      </AdminLabel>
      <AdminLabel htmlFor="payAmount">
        Payment Amount
        <AdminInput
          value={payAmount}
          onChange={(e) => setPayAmount(formatMoney(e.target.value))}
          id="payAmount"
          name="payAmount"
          ref={register}
        />
      </AdminLabel>
      <AdminLabel htmlFor="bookName">
        Book Name
        <AdminSelect
          value={bookName}
          onChange={(e) => setBookName(e.target.value)}
          id="bookName"
          name="bookName"
          ref={register}
        >
          {bookOptions.map((option, i) => {
            return (
              <option key={i} value={option.value}>
                {option.key}
              </option>
            );
          })}
        </AdminSelect>
      </AdminLabel>
      <AdminLabel htmlFor="payDate">
        Pay Date
        <AdminInput
          value={payDate}
          onChange={(e) => setPayDate(formatDate(e.target.value))}
          id="payDate"
          name="payDate"
          ref={register}
          placeholder="MM-DD-YYYY"
        />
      </AdminLabel>
      <Submit type="submit">Log Payment</Submit>
    </AdminForm>
  );
}

export default Recordpayment;
