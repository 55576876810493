import React from "react";

import styles from "./Styles/swipehero.module.css";

import Savings from "../../images/svgs/Savings";
import { AnimatedOpacity } from "../../helpers/reusables";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";

const SwipeHero = () => {
  return (
    <AnimatedOpacity
      style={{
        backgroundColor: "#fafafa",
        borderTop: "1px solid lightgray",
        borderBottom: "1px solid lightgray",
      }}
    >
      <div className={styles.swipeHeroContainer}>
        <div className={styles.swipeHeroTextContainer}>
          <h2 className={styles.swipeHeroTitle}>Introducing</h2>
          <h2 className={styles.swipeHeroLogo}>Swipe 38</h2>
          <h4 className={styles.swipeHeroTag}>
            Unlimited payment processing for as low as $38 / month.
          </h4>
          <motion.div
            style={{ width: "max-content" }}
            whileTap={{ scale: 0.9 }}
          >
            <Link to="/request-quote" className={styles.heroButton}>
              Learn More
            </Link>
          </motion.div>
        </div>
        <div className={styles.swipeHeroSVGContainer}>
          <Savings className={styles.swipeHeroSVG} />
        </div>
      </div>
    </AnimatedOpacity>
  );
};

export default SwipeHero;
