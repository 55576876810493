import React from "react";

import { Container, Step, StepText, StepTitle, StepInfo } from "./Styles/Story";

import { IoMdCash } from "react-icons/io";
import { FaHourglassStart } from "react-icons/fa";
import { AiOutlineCompass, AiOutlineUnorderedList } from "react-icons/ai";

import "./Styles/Story.css";

function Story() {
  return (
    <Container>
      <Step>
        <FaHourglassStart className="story-icon" />
        <StepInfo>
          <StepTitle>HOW DID IT ALL BEGIN?</StepTitle>
          <StepText>
            The best way to earn trust with clients is by providing exceptional
            service, saving them money and delivering on all promises. In order
            to ensure that these can always be delivered on, we had to take
            matters into our own hands.
          </StepText>
        </StepInfo>
      </Step>
      <Step>
        <AiOutlineCompass className="story-icon" />
        <StepInfo>
          <StepTitle>WHY DO WE DO WHAT WE DO?</StepTitle>
          <StepText>
            Swipe Save was founded in order shine a new light on merchant
            services. No longer are the days of cumbersome contracts and over
            inflated processing rates. We believe that every business owner
            deserves fair pricing.
          </StepText>
        </StepInfo>
      </Step>
      <Step>
        <AiOutlineUnorderedList className="story-icon" />
        <StepInfo>
          <StepTitle>HOW WE ACCOMPLISH OUR GOALS</StepTitle>
          <StepText>
            We are consitently moving towards creating a better future for the
            business owners across America. Swipe Save works hard to build a
            honest team dedicated to the our cause.
          </StepText>
        </StepInfo>
      </Step>
      <Step>
        <IoMdCash className="story-icon" />
        <StepInfo>
          <StepTitle>WHAT IS THE NEXT STEP?</StepTitle>
          <StepText>
            It is our goal to provide processing solutions on a global scale and
            to do that we need your help. If you are a business owner and you
            feel that you might be paying too much for processing, let our team
            of expert analysts do the work for you.
          </StepText>
        </StepInfo>
      </Step>
    </Container>
  );
}

export default Story;
