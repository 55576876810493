import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import ContactForm from "../ContactForm";

const QuoteTool = () => {
  return (
    <>
      <Header />
      <ContactForm quote title={"Request Quote"} />
      <Footer />
    </>
  );
};

export default QuoteTool;
