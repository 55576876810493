import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../CallToAction/Styles/CallToAction";

import {
  Container,
  Title,
  List,
  Item,
  ExpectLink,
} from "./Styles/Expectations";

function Expectations() {
  return (
    <Container>
      <Title>Here's what Swipe Save merchants have come to expect</Title>
      <List>
        <Item>No contract or termination fee, ever</Item>
        <Item>Next Day Availability of Funds</Item>
        <Item>True PCI Compliance Support</Item>
        <Item>Best Rates From the Start</Item>
        <Item>Wide Variety of Free Equipment</Item>
        <Item>Reps You Can Count On</Item>
      </List>
      {/* <Button dark>
        <Link
          style={{ textDecoration: "none", color: "#3d3d3d" }}
          to="/request-quote"
        >
          Start Now!
        </Link>
      </Button> */}
    </Container>
  );
}

export default Expectations;
