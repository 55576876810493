import styled from "styled-components";

import theme from "../../../config/theme";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
`;

export const Step = styled.div`
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${theme.light};
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  border-radius: 10px;
  padding: 1rem;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    width: 70%;
    margin: 1rem auto;
    padding: 1rem;
  }
`;

export const StepText = styled.p`
  width: 95%;

  @media screen and (max-width: 500px) {
    text-align: center;
    margin: 0 auto;
  }
`;

export const StepTitle = styled.h2`
  margin-bottom: 0;
  color: ${theme.primary};

  @media screen and (max-width: 500px) {
    text-align: center;
    font-weight: 300;
    margin: 0.5rem;
  }
`;

export const StepInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;
