import React from "react";

import Header from "../Header";
import Footer from "../Footer";
import PageHeader from "../PageHeader";
import Values from "../Values";
import Story from "../Story";
import CallToAction from "../CallToAction";
import { AnimatedOpacity } from "../../helpers/reusables";

function About() {
  return (
    <>
      <Header />
      <AnimatedOpacity>
        <Values />
        <PageHeader title="Our Story" />
        <Story />
        <CallToAction dark />
      </AnimatedOpacity>
      <Footer />
    </>
  );
}

export default About;
