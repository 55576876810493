import React, { useState } from "react";

import CRMControl from "./CRMControl";

import { CRMContainer, CRMNav, CRMNavLink, CRMScreen } from "./Styles/CRM";

import { AiOutlineUserAdd } from "react-icons/ai";
import { HiOutlineViewList } from "react-icons/hi";
import { AnimatedOpacity } from "../../../../helpers/reusables";

function CRM() {
  const [screen, setScreen] = useState("viewleads");

  return (
    <AnimatedOpacity dealy={0.2}>
      <CRMContainer>
        <CRMNav>
          <CRMNavLink
            onClick={() => setScreen("viewleads")}
            active={screen === "viewleads"}
          >
            <HiOutlineViewList />
            View Leads
          </CRMNavLink>
          <CRMNavLink
            onClick={() => setScreen("newlead")}
            active={screen === "newlead"}
          >
            <AiOutlineUserAdd />
            New Lead
          </CRMNavLink>
        </CRMNav>
        <CRMScreen>
          <CRMControl screen={screen} />
        </CRMScreen>
      </CRMContainer>
    </AnimatedOpacity>
  );
}

export default CRM;
