import React, { useState } from "react";
import fire from "../../../helpers/firebase";
import { getEmployeeProfile } from "../../../helpers/helpers";
import { AnimatedOpacity } from "../../../helpers/reusables";

import {
  Container,
  Data,
  Point,
  ProfileContainer,
  ProfileDate,
  ProfileImage,
  ProfileInfo,
  ProfileName,
  ProfileTag,
  Total,
  Value,
  ValueText,
  Widget,
  WidgetTitle,
} from "../Styles/AgentDashboard";

function Home({ profile }) {
  const [deals, setDeals] = useState([]);
  const [mtd, setMtd] = useState("");
  const [ytd, setYtd] = useState("");
  const [backupProfile, setBackupProfile] = useState();
  let db = fire.firestore();

  async function getDeals() {
    const snapshot = await db
      .collection("users")
      .doc(fire.auth().currentUser.uid)
      .collection("merchantList")
      .get();
    let dealArray = [];
    snapshot.docs.map((doc) => dealArray.push(doc.data().merchant));
    setDeals(dealArray);
  }
  if (deals.length < 1) {
    getDeals();
  }

  if (deals.length > 0 && !mtd && !ytd) {
    let month = 0;
    let year = 0;
    let date = new Date();
    deals.map((deal) => {
      let splitDate = deal.approval.split("-");
      if (parseInt(splitDate[1]) === date.getMonth() + 1) {
        month++;
      }
      if (parseInt(splitDate[2]) === date.getFullYear()) {
        year++;
      }
    });
    setMtd(month.toString());
    setYtd(year.toString());
  }

  if (!profile && fire.auth().currentUser && !backupProfile) {
    setBackupProfile(getEmployeeProfile(fire.auth().currentUser.id));
  }

  if (!profile) {
    profile = backupProfile;
  }

  return (
    <AnimatedOpacity delay={0.2}>
      <Container>
        <ProfileContainer>
          <ProfileImage src={profile.profileImage} />
          <ProfileInfo>
            <ProfileName>
              {profile.firstName} {profile.lastName}
            </ProfileName>
            <ProfileTag>{profile.tag}</ProfileTag>
            <ProfileDate>Agent Since : {profile.dateJoined}</ProfileDate>
          </ProfileInfo>
        </ProfileContainer>
        <Widget>
          <WidgetTitle>Month to Date</WidgetTitle>
          <Data>
            <Point>
              <Value>{mtd}</Value>
              <ValueText>New MIDs</ValueText>
            </Point>
          </Data>
        </Widget>
        <Widget>
          <WidgetTitle>Year to Date</WidgetTitle>
          <Data>
            <Point>
              <Value>{ytd}</Value>
              <ValueText>New MIDs</ValueText>
            </Point>
          </Data>
        </Widget>
        <Total></Total>
      </Container>
    </AnimatedOpacity>
  );
}

export default Home;
