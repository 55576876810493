import styled from "styled-components";

import theme from "../../../config/theme";

export const TestimonialsContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
`;

export const Testimonial = styled.div`
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${theme.primary};
  font-size: 1.2rem;
  margin: 0;
`;

export const Info = styled.p`
  color: ${theme.dark};
  font-size: 1.2rem;
`;

export const Image = styled.img`
  max-width: 30%;
  border: 5px solid ${theme.light};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 3rem;
`;

export const SectionTitle = styled.h2`
  color: ${theme.secondary};
  font-size: 2.4rem;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
