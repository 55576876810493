import React from "react";
import { AnimatedOpacity } from "../../helpers/reusables";
import styles from "./Styles/whyswipe.module.css";

const WhySwipe = () => {
  return (
    <AnimatedOpacity delay={0.2}>
      <section className={styles.whySwipe}>
        <div className={styles.container}>
          <div className={styles.columnGroup}>
            <div className={styles.column}>
              <h2 className={styles.columnTitle}>Get Your Money Fast</h2>
              <p className={styles.columnBody}>
                We collaborate with industry leading providers so you can get
                your money as soon as next day.
              </p>
            </div>
            <div className={styles.column}>
              <h2 className={styles.columnTitle}>24 / 7 Support</h2>
              <p className={styles.columnBody}>
                Customer satisfaction is important to us. All of our clients
                have access to 24/7 support so you're always covered.
              </p>
            </div>
          </div>
          <div className={styles.columnGroup}>
            <div className={styles.column}>
              <h2 className={styles.columnTitle}>Month to Month</h2>
              <p className={styles.columnBody}>
                We believe in our model so much that we don't do contracts. We
                make it a point to earn your business each and every day.
              </p>
            </div>
            <div className={styles.column}>
              <h2 className={styles.columnTitle}>Transparent Pricing</h2>
              <p className={styles.columnBody}>
                Annual, Hidden or Rouge Fees. We don't care what you call them,
                we don't like them. Know exaclty what you are paying for with
                Swipe Save.
              </p>
            </div>
          </div>
        </div>
      </section>
    </AnimatedOpacity>
  );
};

export default WhySwipe;
