const theme = {
  primary: "#2d529c",
  secondary: "#0c3264",
  light: "#fff",
  dark: "#3d3d3d",
  alert: "#e37262",
  inactive: "#b9b9b9",
  hover: "#4b88d8",
  input: "#f9fbff",
  tint: "#eeeeee",
};

export default theme;
