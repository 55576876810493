import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Submit } from "../../../Styles/AgentDashboard";

import {
  NewLeadContainer,
  Input,
  Label,
  TextArea,
  NewLeadForm,
  InputGroup,
} from "../Styles/CRM";

import fire from "../../../../../helpers/firebase";

function NewLead() {
  const { register, handleSubmit, watch, errors } = useForm();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyStreet, setCompanyStreet] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyZip, setCompanyZip] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [monthlyRev, setMonthlyRev] = useState("");
  const [currentFees, setCurrentFees] = useState("");
  const [notes, setNotes] = useState("");

  let db = fire.firestore();

  const clearInputs = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCompanyStreet("");
    setCompanyState("");
    setCompanyCity("");
    setCompanyZip("");
    setEmail("");
    setPhoneNum("");
    setMonthlyRev("");
    setCurrentFees("");
    setNotes("");
    setCompanyRole("");
  };

  const onSubmit = (data) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "-" + dd + "-" + yyyy;
    let lead = {
      companyCity: data.companyCity,
      companyName: data.companyName,
      companyRole: data.companyRole,
      companyState: data.companyState,
      companyStreet: data.companyStreet,
      companyZip: data.companyZip,
      currentFees: data.currentFees,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      monthlyRev: data.monthlyRev,
      notes: [{ key: today, value: data.notes }],
      phoneNumber: data.phoneNumber,
    };

    let leadRef = db
      .collection("leads")
      .doc(fire.auth().currentUser.uid)
      .collection("leadList");

    leadRef.add({ lead: { ...lead, dateCreated: today } });
    clearInputs();
  };

  return (
    <NewLeadContainer>
      <NewLeadForm onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <Label htmlFor="firstName">
            First Name
            <Input
              ref={register}
              name="firstName"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Label>
          <Label htmlFor="lastName">
            Last Name
            <Input
              ref={register}
              name="lastName"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="companyRole">
            Company Role
            <Input
              ref={register}
              name="companyRole"
              id="companyRole"
              value={companyRole}
              onChange={(e) => setCompanyRole(e.target.value)}
            />
          </Label>
          <Label htmlFor="companyName">
            Company Name
            <Input
              ref={register}
              name="companyName"
              id="companyName"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="companyStreet">
            Company Street
            <Input
              ref={register}
              name="companyStreet"
              id="companyStreet"
              value={companyStreet}
              onChange={(e) => setCompanyStreet(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="companyCity">
            Company City
            <Input
              ref={register}
              name="companyCity"
              id="companyCity"
              value={companyCity}
              onChange={(e) => setCompanyCity(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="companyState">
            Company State
            <Input
              ref={register}
              name="companyState"
              id="companyState"
              value={companyState}
              onChange={(e) => setCompanyState(e.target.value)}
            />
          </Label>
          <Label htmlFor="companyZip">
            Company Zip
            <Input
              ref={register}
              name="companyZip"
              id="companyZip"
              value={companyZip}
              onChange={(e) => setCompanyZip(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="email">
            Email
            <Input
              ref={register}
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Label>
          <Label htmlFor="phoneNumber">
            Phone Number
            <Input
              ref={register}
              name="phoneNumber"
              id="phoneNumber"
              value={phoneNum}
              onChange={(e) => setPhoneNum(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="monthlyRev">
            Monthly Revenue
            <Input
              ref={register}
              name="monthlyRev"
              id="monthlyRev"
              value={monthlyRev}
              onChange={(e) => setMonthlyRev(e.target.value)}
            />
          </Label>
          <Label htmlFor="currentFees">
            Current Fees:
            <Input
              ref={register}
              name="currentFees"
              id="currentFees"
              value={currentFees}
              onChange={(e) => setCurrentFees(e.target.value)}
            />
          </Label>
        </InputGroup>
        {/* <InputGroup>
          <Label htmlFor="">
            Statement Upload
            <Input
              type="file"
              ref={register}
              name="statement"
              id="statement"
              value={statement}
              onChange={(e) => setStatement(e.target.value)}
            />
          </Label>
        </InputGroup> */}
        <InputGroup>
          <Label htmlFor="notes">
            Notes:
            <TextArea
              ref={register}
              value={notes}
              id="notes"
              name="notes"
              onChange={(e) => setNotes(e.target.value)}
            />
          </Label>
        </InputGroup>
        <InputGroup>
          <Submit type="submit">Record Lead</Submit>
        </InputGroup>
      </NewLeadForm>
    </NewLeadContainer>
  );
}

export default NewLead;
