import React, { useState } from "react";

import { Container, FaqSection, Question, Answer } from "./Styles/Faq";

import { BsChevronDown, BsChevronUp } from "react-icons/bs";

import { SectionTitle } from "../Testimonials/Styles/Testimonials";

import "./Styles/Faq.css";
import { AnimatedOpacity } from "../../helpers/reusables";

const questions = [
  {
    question: "What is Swipe Save Consulting?",
    answer:
      "Swipe Save does exactly what it's name would suggest, we save on swipes. We negotiated with more than 60 individual processors, some of the most trusted names in processing, to secure the most affordable rates that we could find. We take pride in what we do and we are constantly searching for new partners who can provide more value for our clients.",
  },
  {
    question: "How do I know these are going to be the best rate?",
    answer:
      "We get this question all the time, and rightfully so. Far too often processors hit you with sneaky linguistics and shady tactics and before you know it, you're still paying too much. In order to instill confidence in our customers, not only are you never under any contract to process with us, if you find a better rate from someone else and we can't beat it, we'll give you $100 and assist you in caneclling your serivce with us. I know, it's crazy... but we're crazy.",
  },
  {
    question: "Are there any contracts?",
    answer:
      "No, you are never locked into processing with Swipe Save or any of our partners. We are so confident in our ability to provide affordable and quality service that we did away with the traditional processing contracts. You decide if we deserve your business, not a piece of paper",
  },
  {
    question: "How do I start processing?",
    answer:
      "Great question! Taking payments has never been easier. You can submit a merchant application through our online platform or schedule a call with one of our specialists and we can have a terminal in your hand in as little as two business days!",
  },
];

const Section = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <FaqSection onClick={() => setOpen((s) => (s = !s))}>
      <Question>
        {question}
        {open ? (
          <BsChevronDown className="chevron" />
        ) : (
          <BsChevronUp className="chevron" />
        )}
      </Question>
      {open ? (
        <AnimatedOpacity delay={0.2}>
          <Answer>{answer}</Answer>
        </AnimatedOpacity>
      ) : null}
    </FaqSection>
  );
};

function Faq() {
  return (
    <Container>
      <SectionTitle>Frequently Asked Questions</SectionTitle>
      {questions.map((question, i) => {
        return (
          <Section
            key={i}
            question={question.question}
            answer={question.answer}
          />
        );
      })}
    </Container>
  );
}

export default Faq;
