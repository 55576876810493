import React from "react";

import { useForm } from "react-hook-form";
import PageHeader from "../PageHeader";

import fire from "firebase";

import {
  Form,
  Input,
  Label,
  SubmitButton,
  TextArea,
  InputGroup,
  Checkbox,
  SectionTitle,
  CheckboxGroup,
  Upload,
} from "./Styles/ContactForm";

import styles from "./Styles/contact.module.css";

function ContactForm({ work, full, noheader, title, quote }) {
  const { register, handleSubmit } = useForm();

  const db = fire.firestore();

  const getSource = () => {};

  const onSubmit = (data) => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "-" + dd + "-" + yyyy;
    let submission = data;

    let leadRef = db
      .collection("formSubmissions")
      .doc(data.message ? "contact" : "career")
      .collection("submissions");

    leadRef.add({ submission: { ...submission, dateCreated: today } });
  };

  title = title ? title : "Contact Us";

  return (
    <>
      {noheader ? null : (
        <div className={styles.paddingTop} style={{ width: "100%" }}>
          <PageHeader title={title} />
        </div>
      )}
      <Form full={full} onSubmit={handleSubmit(onSubmit)}>
        <InputGroup>
          <Label htmlFor="name">
            Name:
            <Input name="name" id="name" ref={register} />
          </Label>

          <Label htmlFor="businessName">
            Business Name:
            <Input name="businessName" id="businesName" ref={register} />
          </Label>
        </InputGroup>

        <InputGroup>
          <Label htmlFor="emailAddress">
            Email Address:
            <Input name="emailAddress" id="emailAddress" ref={register} />
          </Label>

          <Label htmlFor="phoneNumber">
            Phone Number:
            <Input name="phoneNumber" id="phoneNumber" ref={register} />
          </Label>
        </InputGroup>

        {work || quote ? null : (
          <>
            <CheckboxGroup>
              <SectionTitle>I am currently...</SectionTitle>
              <Label htmlFor="swipesave">
                <Checkbox
                  type="checkbox"
                  name="swipesave"
                  id="swipesave"
                  ref={register}
                />
                with Swipe Save.
              </Label>
              <Label htmlFor="competitor">
                <Checkbox
                  type="checkbox"
                  name="competitor"
                  id="competitor"
                  ref={register}
                />
                with another company.
              </Label>
            </CheckboxGroup>

            <InputGroup>
              <Label htmlFor="message">
                Message:
                <TextArea name="message" id="message" ref={register} />
              </Label>
            </InputGroup>
          </>
        )}

        {quote ? (
          <InputGroup>
            <Label htmlFor="processingvolume">
              Processing Volume:
              <Input
                name="processingvolume"
                id="processingvolume"
                ref={register}
              />
            </Label>
          </InputGroup>
        ) : null}

        <InputGroup>
          <Label htmlFor="statement">
            {work
              ? "Please attach a resume and cover letter if applicable"
              : "Upload Processing Statement:"}
            <Upload type="file" name="statement" ref={register} />
          </Label>
        </InputGroup>

        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
    </>
  );
}

export default ContactForm;
