import styled from "styled-components";

import theme from "../../../config/theme";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 4rem 0;

  @media screen and (max-width: 900px) {
    padding-top: 15vh;
  }
`;

export const Value = styled.h2`
  color: ${theme.primary};
  font-size: 3rem;
  margin: 0;
`;

export const Tag = styled.p`
  color: ${theme.inactive};
  font-style: italic;
  font-size: 1.5rem;
  margin: 0;
  text-align: center;
  width: 95%;
  max-width: 1000px;
`;

export const Divider = styled.hr`
  width: 10%;
  color: ${theme.dark};
  margin: 1.5rem 0;
`;

export const Info = styled.p`
  color: ${theme.dark};
  width: 95%;
  max-width: 750px;
  margin: 0 auto;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  text-align: center;
`;
