import styled from "styled-components";
import theme from "../../../config/theme";

export const Profile = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;
  width: 60%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 3rem;
`;

export const ProfileImage = styled.img`
  border-radius: 100%;
  border: 10px solid white;
  max-width: 20%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const Name = styled.h3`
  margin: 1rem 0 0 0;
  font-size: 2rem;
  color: ${theme.primary};
`;

export const Title = styled.p`
  margin: 0 0 2rem 0;
  color: ${theme.dark};
  font-style: italic;
`;

export const Contact = styled.div``;

export const ContactInfo = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

export const Bio = styled.div`
  width: 60%;
`;

export const Divider = styled.hr`
  color: ${theme.primary};
  width: 50%;
  margin: 2rem auto;
`;

export const Tag = styled.p`
  color: ${theme.primary};
  font-size: 1.4rem;
  font-weight: bold;
`;

export const BioText = styled.p`
  color: ${theme.dark};
  margin: 0 auto;
`;

export const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a``;
