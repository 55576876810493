import React from "react";

import {
  ReviewContainer,
  Review,
  Title,
  Name,
  Info,
  TextContainer,
  StarContainer,
  Star,
  ReviewsTitle,
} from "./Styles/Reviews";

import { SectionTitle } from "../Testimonials/Styles/Testimonials";

import "./Styles/Reviews.css";

function Reviews() {
  return (
    <>
      <ReviewsTitle>What our customers say</ReviewsTitle>
      <ReviewContainer>
        <Review>
          <StarContainer>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </StarContainer>
          <TextContainer>
            <Title>Quick fix to a huge problem.</Title>
            <Info>
              Submitted a statement to see what kind of savings were possible. I
              had no intention of switching processors, but when I found out how
              much I was over paying it was a no brainer. 3 days later I had a
              new terminal. On our first month with Swipe Save, we saved a
              little over $300. Thanks again Shaun.
            </Info>
            <Name>Joseph Langel</Name>
          </TextContainer>
        </Review>
        <Review>
          <StarContainer>
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </StarContainer>
          <TextContainer>
            <Title>Incredible service, even better people.</Title>
            <Info>
              I’ve never thought about switching processors, I was with my
              previous company for 10 years. I heard about Swipe Save through a
              friend of mine who had nothing but great things to say. Fast
              forward two weeks and I made the call. Ashlie was fantastic, she
              explained everything to me and helped to walk me through every
              step. I recommend Swipe Save every time I meet a business owner at
              my networking events. I saved $600 just last month!
            </Info>
            <Name>Ashley Vital</Name>
          </TextContainer>
        </Review>
      </ReviewContainer>
    </>
  );
}

export default Reviews;
