import fire from "./firebase";

export const getEmployeeProfile = (uid) => {
  let db = fire.firestore();
  return db
    .collection("users")
    .doc(uid)
    .get()
    .then((data) => {
      return data.data();
    });
};

export const formatDate = (val) => {
  const input = val.replace(/\D/g, "").substring(0, 8);
  const month = input.substring(0, 2);
  const day = input.substring(2, 4);
  const year = input.substring(4, 8);
  if (input.length > 4) {
    return `${month}-${day}-${year}`;
  } else if (input.length > 2) {
    if (parseInt(day) > 31) {
      return `${month}/31`;
    } else {
      return `${month}-${day}`;
    }
  } else if (input.length > 0) {
    if (parseInt(month) > 12) {
      return `12`;
    } else {
      return `${month}`;
    }
  }
};

export const hasLetters = (value) => {
  var regExp = /[a-zA-Z]/g;
  if (regExp.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const formatMoney = (value) => {
  if (hasLetters(value)) {
    return "";
  }
  return parseFloat(value).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
};

export const bookOptions = [
  { key: "Pay Bright", value: "Pay Bright" },
  { key: "Retriever Merchant Services", value: "RMS" },
];

export const formatPhone = (val) => {
  if (hasLetters(val)) {
    return "";
  }
  const input = val.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  if (input.length > 6) {
    return `(${zip}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    return `(${zip}) ${middle}`;
  } else if (input.length > 0) {
    return `(${zip}`;
  }
};
