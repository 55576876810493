import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={300}
      height={200}
      viewBox="0 0 932 567.586"
      {...props}
    >
      <path
        data-name="Path 438"
        d="M39.377 518.226a24.215 24.215 0 0023.383-4.118c8.19-6.875 10.758-18.196 12.846-28.682l6.18-31.017-12.938 8.909c-9.304 6.406-18.818 13.018-25.26 22.297s-9.252 21.948-4.078 31.988"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 439"
        d="M41.375 557.937c-1.629-11.864-3.304-23.88-2.16-35.872 1.015-10.649 4.265-21.048 10.88-29.579a49.206 49.206 0 0112.624-11.44c1.262-.797 2.424 1.203 1.167 1.997a46.78 46.78 0 00-18.504 22.325c-4.029 10.246-4.676 21.416-3.982 32.3.42 6.583 1.31 13.122 2.206 19.653a1.198 1.198 0 01-.808 1.423 1.163 1.163 0 01-1.423-.808z"
        fill="#f2f2f2"
      />
      <path
        data-name="Path 442"
        d="M53.096 538.9a17.825 17.825 0 0015.531 8.02c7.864-.374 14.418-5.86 20.317-11.071l17.452-15.409-11.55-.553c-8.306-.397-16.826-.77-24.738 1.794s-15.207 8.726-16.654 16.915"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 443"
        d="M36.772 564.757c7.84-13.872 16.932-29.288 33.18-34.216a37.026 37.026 0 0113.956-1.44c1.482.127 1.112 2.411-.367 2.284a34.398 34.398 0 00-22.272 5.892c-6.28 4.274-11.17 10.217-15.308 16.519-2.535 3.86-4.805 7.884-7.076 11.903-.726 1.284-2.847.357-2.113-.942z"
        fill="#f2f2f2"
      />
      <path
        data-name="Rectangle 62"
        fill="#e6e6e6"
        d="M175.252 17.775h648.366v307.661H175.252z"
      />
      <path
        data-name="Rectangle 75"
        fill="#fff"
        d="M193.793 44.158h611.286v256.691H193.793z"
      />
      <path
        data-name="Rectangle 80"
        fill="#356eb5"
        d="M174.976 0h648.366v27.544H174.976z"
      />
      <circle
        data-name="Ellipse 90"
        cx={195.445}
        cy={12.741}
        r={5.105}
        fill="#fff"
      />
      <circle
        data-name="Ellipse 91"
        cx={214.823}
        cy={12.741}
        r={5.105}
        fill="#fff"
      />
      <circle
        data-name="Ellipse 92"
        cx={234.202}
        cy={12.741}
        r={5.105}
        fill="#fff"
      />
      <path
        d="M338.167 240.79h308.951v-52.953a41.929 41.929 0 00-12.86-29.866l-.084-.082c-5.518-5.537-23.96-19.589-43.04-18.397-10.079.627-18.708 5.485-25.647 14.437-21.6 27.878-49.972 18.468-64.962 10.377-13.21-7.131-26.702-10.478-40.102-9.945-18.767.733-45.182 7.647-64.446 36.417-7.358 10.98-23.36 25.883-57.81 34.642z"
        fill="#e4e4e4"
        style={{
          isolation: "isolate",
        }}
      />
      <path
        d="M664.598 242.021H338.762a1.02 1.02 0 01-1.02-1.02V84.828a1.02 1.02 0 012.04 0v155.154h324.816a1.02 1.02 0 010 2.04z"
        fill="#3f3d56"
      />
      <path
        fill="#3f3d56"
        d="M398.741 181.965h2v58.622h-2zM458.618 154.586h2v86h-2zM518.618 172.586h2v68h-2zM578.618 139.586h2v101h-2zM638.618 162.586h2v78h-2z"
      />
      <circle cx={459.741} cy={155.965} r={6.118} fill="#356eb5" />
      <circle cx={579.741} cy={139.965} r={6.118} fill="#356eb5" />
      <circle cx={519.741} cy={172.965} r={6.118} fill="#356eb5" />
      <circle cx={399.741} cy={181.965} r={6.118} fill="#356eb5" />
      <circle cx={639.741} cy={161.965} r={6.118} fill="#356eb5" />
      <path
        fill="#ffb6b6"
        d="M136.913 555.469h12.26l5.832-47.288-18.094.001.002 47.287z"
      />
      <path
        d="M133.785 551.467l24.144-.001h.001a15.387 15.387 0 0115.387 15.386v.5l-39.53.002z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb6b6"
        d="M194.913 555.469h12.26l5.832-47.288-18.094.001.002 47.287z"
      />
      <path
        d="M191.785 551.467l24.144-.001h.001a15.387 15.387 0 0115.387 15.386v.5l-39.53.002z"
        fill="#2f2e41"
      />
      <path
        d="M217.793 339.284a10.743 10.743 0 00-2.513-16.28l-19.95-95.789-21.737 8.564 26.446 91.465a10.8 10.8 0 0017.754 12.04z"
        fill="#ffb6b6"
      />
      <path
        d="M123.764 320.838l-9.036 9.173s-9.688 35.503 8.85 79.29l12.4 126.95 22.31 1.68 6.66-147.697 30.73 49.026-5.367 98.145 24.661-.567 11.146-108.752-30.44-113.484z"
        fill="#2f2e41"
      />
      <path
        d="M155.36 333.195a345.947 345.947 0 01-40.698-2.688l-.543-.072 13.466-59.186-4.637-76.15 43.788-1.5a16.09 16.09 0 0116.505 13.763l8.962 60.755 8.29 53.903-.111.162c-5.957 8.735-25.876 11.013-45.021 11.013z"
        fill="#356eb5"
      />
      <path
        d="M162.752 206.193l8.897-11.153s15.597-10.666 20.533 12.19 14.107 59.96 14.107 59.96l-30.498 2.47z"
        fill="#356eb5"
      />
      <path
        d="M78.443 340.284a10.743 10.743 0 012.513-16.28l19.951-95.789 21.736 8.564-26.446 91.465a10.8 10.8 0 01-17.754 12.04z"
        fill="#ffb6b6"
      />
      <path
        d="M133.484 207.193l-8.897-11.153s-15.597-10.666-20.533 12.19-14.107 59.96-14.107 59.96l30.498 2.47z"
        fill="#356eb5"
      />
      <path
        d="M170.978 182.461h-58a4.505 4.505 0 01-4.5-4.5v-25a33.5 33.5 0 1167 0v25a4.505 4.505 0 01-4.5 4.5z"
        fill="#2f2e41"
      />
      <circle cx={148.382} cy={156.394} r={24.561} fill="#ffb6b6" />
      <path
        d="M183.404 155.461h-35.466l-.364-5.091-1.818 5.091h-5.461l-.72-10.091-3.605 10.091h-10.566v-.5a26.53 26.53 0 0126.5-26.5h5a26.53 26.53 0 0126.5 26.5z"
        fill="#2f2e41"
      />
      <path
        d="M147.648 187.128a4.596 4.596 0 01-.797-.07l-25.97-4.582v-42.92h28.588l-.707.825c-9.848 11.484-2.429 30.107 2.87 40.185a4.433 4.433 0 01-.353 4.707 4.482 4.482 0 01-3.631 1.855z"
        fill="#2f2e41"
      />
      <path
        fill="#9e616a"
        d="M864.323 555.469h-12.259l-5.833-47.288 18.095.001-.003 47.287z"
      />
      <path
        d="M867.45 567.353l-39.53-.001v-.5a15.387 15.387 0 0115.386-15.386h24.145z"
        fill="#2f2e41"
      />
      <path
        fill="#9e616a"
        d="M820.323 555.469h-12.259l-5.833-47.288 18.095.001-.003 47.287z"
      />
      <path
        d="M823.45 567.353l-39.53-.001v-.5a15.387 15.387 0 0115.386-15.386h24.145z"
        fill="#2f2e41"
      />
      <path
        d="M677.538 224.113a11.396 11.396 0 0017.434 1.195l24.258 9.473 9.353-13.338-34.446-12.896a11.458 11.458 0 00-16.6 15.566z"
        fill="#9e616a"
      />
      <path
        fill="#2f2e41"
        d="M820.169 304.925l-31.94 10.419 10.67 226.196 24.65.61 12.615-159.517 2.865 158.887 30.479 1.68 11.236-238.275h-60.575z"
      />
      <path
        d="M798.618 171.586l81.975-17.81 9.116 70.584-7.59 33.726 3.04 51.254s-64.82 32.261-96.93 6.004c0 0 25.809-67.79 10.85-99.524z"
        fill="#3f3d56"
      />
      <path
        d="M812.815 181.57l-14.197-9.984s-.838-1.161-12.67 12.67-35.83 35.83-35.83 35.83l-40-15-7.287 26.925 46.488 21.664 65.816-43.955z"
        fill="#3f3d56"
      />
      <path
        d="M885.173 335.799a11.396 11.396 0 004.422-16.906l13.827-22.07-11.362-11.673-19.088 31.44a11.458 11.458 0 0012.201 19.209z"
        fill="#9e616a"
      />
      <path
        d="M867.807 167.68l12.786-13.905s14.246-4.961 25.886 20.675 24.64 77.636 24.64 77.636l-30.396 58.547-23.605-10.547 23.605-48.413-24.979-35.853z"
        fill="#3f3d56"
      />
      <circle
        cx={967.363}
        cy={290.844}
        r={26.609}
        transform="rotate(-28.663 575.084 469.988)"
        fill="#9e616a"
      />
      <path
        d="M858.39 103.365l1.29-2.597-6.492-3.225s-7.16-11.65-20.117-8.377-18.787 5.234-18.787 5.234l-6.475 3.258 3.25 3.233-5.83 1.96 3.895 1.935-4.532 2.604 2.44 13.352s4.051-10.127 11.84-6.257 22.037-2 22.037-2l12.378 23.955s2.553-8.398 7.106-6.159c0 0 11.631-18.523-2.004-26.916z"
        fill="#2f2e41"
      />
      <path
        d="M300 567.586H1a1 1 0 010-2h299a1 1 0 010 2zM931 567.586H702a1 1 0 010-2h229a1 1 0 010 2z"
        fill="#cacaca"
      />
    </svg>
  );
}

export default SvgComponent;
