import React, { useState } from "react";

import {
  Container,
  Post,
  Hero,
  Text,
  HeroText,
  Overlay,
  List,
  Item,
  Position,
  PositionDesc,
  PositionItem,
  PositionReq,
  PositionTitle,
  Button,
} from "./Styles/Careers";

import PageHeader from "../PageHeader";
import Footer from "../Footer";
import Header from "../Header";

import ContactForm from "../ContactForm";

import styles from "./Styles/careers.module.css";
import { AnimatedOpacity } from "../../helpers/reusables";

function Careers() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Header />
      <AnimatedOpacity>
        <div className={styles.paddingTop} style={{ width: "100%" }}>
          <PageHeader title="Careers" />
        </div>
        <Container>
          <Post>
            <Hero>
              <Overlay>
                <HeroText>Looking for Sales Professionals</HeroText>
              </Overlay>
            </Hero>
            <Text>
              Benefits of Working With
              <br />
              Swipe Save:
            </Text>
            <List>
              <Item>Industry Leading Compensation</Item>
              <Item>Next Gen Processing Platforms</Item>
              <Item>Extensive sales training</Item>
              <Item>Unlimited Earning Potential</Item>
              <Item>Unlimited Time Off</Item>
              <Item>Management Potential</Item>
              <Item>Swipe Save Guarantee!</Item>
            </List>
            <Text>Open Positions:</Text>
            <Position>
              <PositionTitle>Independent Sales Contractor</PositionTitle>
              <PositionTitle>$40,000 - $180,000 / year</PositionTitle>
              <PositionDesc>
                Our team of independent merchant service sales contractors is
                taking the nation by storm and we are looking to expand. As an
                independent sales contractor for Swipe Save you are given the
                opportunity to set your own schedule and be your own boss. We
                are looking for sales professionals from across the United
                States who match the following description.
              </PositionDesc>
              <PositionReq>
                <PositionTitle>I...</PositionTitle>
                <PositionItem>am result driven and motivated.</PositionItem>
                <PositionItem>
                  understand that in order to make money I must work.
                </PositionItem>
                <PositionItem>
                  am interested in setting my own schedule.
                </PositionItem>
                <PositionItem>take pride in how I present myself.</PositionItem>
                <PositionItem>
                  commit to sucess in everything I do.
                </PositionItem>
              </PositionReq>
              <PositionDesc>
                A merchant service sales contractor is responsible for the
                acquisiton and management of processor / merchant relationships.
                It is your duty to prospect leads, set appointments, supply
                processing proposals and close deals in the interest of yourself
                and Swipe Save Consulting. Our team will provide the training
                and tools necessary for you to grow.
              </PositionDesc>
              {open ? null : (
                <Button onClick={() => setOpen((s) => (s = !s))}>
                  Click to Apply
                </Button>
              )}
              {open ? <ContactForm full work noheader /> : null}
            </Position>
          </Post>
        </Container>
      </AnimatedOpacity>
      <Footer />
    </>
  );
}

export default Careers;
