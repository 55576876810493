import React from "react";

import { Container, Title, Tag } from "./Styles/PageHeader";

function PageHeader({ title, tag, image }) {
  return (
    <Container background={image}>
      <Title>{title}</Title>
      <Tag>{tag}</Tag>
    </Container>
  );
}

export default PageHeader;
