import styled from "styled-components";

import theme from "../../../config/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
  background-color: ${theme.primary};
  box-shadow: 0 4px 2px -2px gray;
  height: 10vh;
`;

export const Title = styled.h2`
  color: ${theme.light};
  font-size: 4rem;
  text-align: center;
  margin: 0;
`;

export const Tag = styled.p`
  color: ${theme.light};
  font-size: 1.5rem;
  margin: 0;
`;
