import React, { useState } from "react";
import { useForm } from "react-hook-form";
import fire from "../../../../../helpers/firebase";
import { bookOptions, formatDate } from "../../../../../helpers/helpers";
import {
  AdminForm,
  AdminInput,
  AdminLabel,
  AdminSelect,
  Submit,
} from "../../../Styles/AgentDashboard";

const NewMerchant = () => {
  const { register, handleSubmit } = useForm();
  const [merchantName, setMerchantName] = useState("");
  const [bookName, setBookName] = useState("");
  const [approvalDate, setApprovalDate] = useState("");
  const [repNum, setRepNum] = useState("");
  const [agentID, setAgentID] = useState("");
  const [agentNames, setAgentNames] = useState([]);

  let db = fire.firestore();

  const clearInputs = () => {
    setMerchantName("");
    setBookName("");
    setApprovalDate("");
    setRepNum("");
  };

  async function getAgentNames() {
    const snapshot = await db.collection("users").get();
    let nameArray = [];
    snapshot.docs.map((doc) => {
      let name = doc.data().firstName + " " + doc.data().lastName;
      let id = doc.id;
      nameArray.push({
        name,
        id,
      });
    });
    setAgentNames(nameArray);
  }

  if (agentNames.length === 0) {
    getAgentNames();
  }

  const handleMerchant = (data) => {
    let merchant = {
      name: data.merchantName,
      book: data.bookName,
      approval: data.approvalDate,
    };
    let merchantRef = db
      .collection("users")
      .doc(data.agentID)
      .collection("merchantList")
      .doc(data.merchantName);
    clearInputs();
    merchantRef.set({ merchant });
  };

  return (
    <AdminForm onSubmit={handleSubmit(handleMerchant)}>
      <AdminLabel htmlFor="agentID">
        Agent
        <AdminSelect
          value={agentID}
          onChange={(e) => setAgentID(e.target.value)}
          id="agentID"
          name="agentID"
          ref={register}
        >
          {agentNames && agentNames.length > 0
            ? agentNames.map((agent, i) => {
                return (
                  <option key={i} value={agent.id}>
                    {agent.name}
                  </option>
                );
              })
            : null}
        </AdminSelect>
      </AdminLabel>
      <AdminLabel htmlFor="merchantName">
        Merchant Name
        <AdminInput
          value={merchantName}
          onChange={(e) => setMerchantName(e.target.value)}
          id="merchantName"
          name="merchantName"
          ref={register}
        />
      </AdminLabel>
      <AdminLabel htmlFor="bookName">
        Book Name
        <AdminSelect
          value={bookName}
          onChange={(e) => setBookName(e.target.value)}
          id="bookName"
          name="bookName"
          ref={register}
        >
          {bookOptions.map((option, i) => {
            return (
              <option key={i} value={option.value}>
                {option.key}
              </option>
            );
          })}
        </AdminSelect>
      </AdminLabel>
      <AdminLabel htmlFor="approvalDate">
        Approval Date
        <AdminInput
          value={approvalDate}
          onChange={(e) => setApprovalDate(formatDate(e.target.value))}
          id="approvalDate"
          name="approvalDate"
          ref={register}
          placeholder="MM-DD-YYYY"
        />
      </AdminLabel>
      <Submit type="submit">Add Merchant</Submit>
    </AdminForm>
  );
};

export default NewMerchant;
