import React from "react";

import {
  Container,
  Terminal,
  Image,
  Caption,
  TerminalsContainer,
} from "./Styles/Terminals";

import a920 from "../../images/terminals/a920.png";
import paxs80 from "../../images/terminals/paxs80.png";
import poynt from "../../images/terminals/Poynt.png";
import vx520 from "../../images/terminals/vx520.webp";
import z8 from "../../images/terminals/z8.png";

import { SectionTitle } from "../Testimonials/Styles/Testimonials";

const terminallist = [
  {
    url: a920,
    tag: "Pax A920",
  },
  {
    url: paxs80,
    tag: "Pax S80",
  },
  {
    url: poynt,
    tag: "Poynt",
  },
  {
    url: z8,
    tag: "Dejavoo Z8",
  },
  {
    url: vx520,
    tag: "Vx 520",
  },
];

function Terminals() {
  return (
    <TerminalsContainer>
      <SectionTitle>Variety of Free Equipment Options</SectionTitle>
      <Caption>
        Interested in a particular unit? Reach out and let us know!
      </Caption>
      <Container>
        {terminallist.map((terminal, i) => {
          return (
            <Terminal key={i}>
              <Image src={terminal.url}></Image>
              <Caption>{terminal.tag}</Caption>
            </Terminal>
          );
        })}
      </Container>
    </TerminalsContainer>
  );
}

export default Terminals;
