import React, { useState } from "react";
import {
  Container,
  DownloadContainer,
  DownloadFolder,
  DownloadItem,
  DownloadSectionTitle,
  ResourceContainer,
  SectionTitle,
} from "../Styles/AgentDashboard";

import { BsChevronUp, BsChevronDown, BsDownload } from "react-icons/bs";

import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";
import { AnimatedOpacity } from "../../../helpers/reusables";

function Resources() {
  const [appOpen, setAppOpen] = useState(false);
  const [compOpen, setCompOpen] = useState(false);
  const [formsOpen, setFormsOpen] = useState(false);
  const [marketOpen, setMarketOpen] = useState(false);

  return (
    <AnimatedOpacity delay={0.2}>
      <ResourceContainer>
        <SectionTitle>Resources</SectionTitle>
        <DownloadContainer>
          <DownloadSectionTitle onClick={() => setAppOpen((s) => (s = !s))}>
            Applications
            {appOpen ? (
              <AiFillFolderOpen className="download-icon" />
            ) : (
              <AiFillFolder className="download-icon" />
            )}
          </DownloadSectionTitle>
          {appOpen ? (
            <DownloadFolder>
              <DownloadItem href="" download>
                Credit Policy Restricted List
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Amex OPTBlue Addendum to MPA
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                12 Month Rollover Statment Analysis
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Internet Review Checklist - QRG
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysafe BBVA Standard Form with Coversheet 0219.1.L Editable
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Firearms Dealer Attestation
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Merchant Underwriting Procedures 11.2019
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysage Woodforest Standard Form with Coversheet 0219.1.L
                Editable
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Required Deploymeny Form
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                BBVA Merchant Credit Policy
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                MCC / SIC List
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Beneficial Ownership Addendum to the MPA - 5.2018
                <BsDownload className="download-icon" />
              </DownloadItem>
            </DownloadFolder>
          ) : null}
          <DownloadSectionTitle onClick={() => setCompOpen((s) => (s = !s))}>
            Compliance
            {compOpen ? (
              <AiFillFolderOpen className="download-icon" />
            ) : (
              <AiFillFolder className="download-icon" />
            )}
          </DownloadSectionTitle>
          {compOpen ? (
            <DownloadFolder>
              <DownloadItem href="" download>
                PCI Resources
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                PCI Protection Plan - Data Breach
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                PCI Compliance QRG for Merchants
                <BsDownload className="download-icon" />
              </DownloadItem>
            </DownloadFolder>
          ) : null}
          <DownloadSectionTitle onClick={() => setFormsOpen((s) => (s = !s))}>
            Forms
            {formsOpen ? (
              <AiFillFolderOpen className="download-icon" />
            ) : (
              <AiFillFolder className="download-icon" />
            )}
          </DownloadSectionTitle>
          {formsOpen ? (
            <DownloadFolder>
              <DownloadItem href="" download>
                Wireless Service Addendum
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Addendum to Merchant Aggreement - Opt Blue
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Cancellation Request
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                W9
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                PIN Debit Pricing Addendum
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Cash Discount Merchant Request Form
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysafe Bank Account Change Form (Merchant)
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Surcharge Notification Form
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Extended Services Addendum
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                DBA/Legal Name Change Form
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysafe Equipment Order Form
                <BsDownload className="download-icon" />
              </DownloadItem>
            </DownloadFolder>
          ) : null}
          <DownloadSectionTitle onClick={() => setMarketOpen((s) => (s = !s))}>
            Marketing
            {marketOpen ? (
              <AiFillFolderOpen className="download-icon" />
            ) : (
              <AiFillFolder className="download-icon" />
            )}
          </DownloadSectionTitle>
          {marketOpen ? (
            <DownloadFolder>
              <DownloadItem href="" download>
                Paysade - ISO Advantage
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysafe Beyond Payments
                <BsDownload className="download-icon" />
              </DownloadItem>
              <DownloadItem href="" download>
                Paysafe Beyond Payments (2)
                <BsDownload className="download-icon" />
              </DownloadItem>
            </DownloadFolder>
          ) : null}
        </DownloadContainer>
      </ResourceContainer>
    </AnimatedOpacity>
  );
}

export default Resources;
