import styled from "styled-components";
import theme from "../../../config/theme";

import postimage from "../../../images/careers.jpg";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Post = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 95%;
  max-width: 1000px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding: 0 0 3rem 0;
  border: 1px solid ${theme.primary};
`;

export const Hero = styled.div`
  width: 100%;
  height: 300px;
  object-fit: cover;
  background-image: url(${postimage});
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const Text = styled.h2`
  color: ${theme.primary};
  font-size: 2rem;
  width: 95%;
  text-align: center;

  @media screen and (max-width: 550px) {
    font-weight: 300;
  }
`;

export const HeroText = styled.h3`
  font-size: 4rem;
  color: white;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  text-align: center;

  @media screen and (max-width: 550px) {
    font-size: 3rem;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
`;

export const List = styled.ul`
  padding-left: 0;

  @media screen and (max-width: 550px) {
    list-style-type: none;
  }
`;

export const Item = styled.li`
  font-size: 1.2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 550px) {
    text-align: center;
  }
`;

export const Position = styled.div`
  width: 80%;
  background-color: ${theme.light};
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 1rem;

  &:hover {
    cursor: pointer;
    border: 1px solid ${theme.primary};
  }
`;

export const PositionTitle = styled.p`
  color: ${theme.primary};
  font-size: 1.5rem;
  font-weight: bold;
`;

export const PositionDesc = styled.p`
  color: ${theme.dark};
  margin: 2rem 0;
`;

export const PositionReq = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const PositionItem = styled.li`
  color: ${theme.dark};
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const Button = styled.button`
  font-size: 1.2rem;
  background-color: ${theme.hover};
  border-radius: 10px;
  border-style: none;
  color: ${theme.light};
  padding: 0.8rem;
  width: 70%;
  max-width: 300px;

  &:hover {
    background-color: ${theme.primary};
    cursor: pointer;
  }
`;
