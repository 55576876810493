import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../../config/theme";

export const DashNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.light};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background-color: ${theme.light};
  min-width: 100px;
`;

export const Nav = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
`;

export const NavItem = styled.li`
  font-size: 1rem;
  color: ${(props) => (props.active ? "#2e529c" : "#2e529c")};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: ${(props) => (props.active ? "#2e539c22" : theme.light)};
  margin: 0 auto;
  padding: 1.2rem 0;
  width: 100%;
  border-top: ${(props) => (props.active ? "1px solid lightgray" : "")};
  border-bottom: ${(props) => (props.active ? "1px solid lightgray" : "")};

  svg {
    color: ${(props) => (props.active ? "#2e529c" : "#2e529c")};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  height: 80vh;
  overflow-y: scroll;
`;

// Login Styling

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 400px;
  padding: 1rem;
  border-radius: 10px;
  z-index: 999;
`;

export const LoginLogo = styled.img`
  max-width: 80%;
  margin-bottom: 2rem;
`;

export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Input = styled.input`
  border: 1px solid rgba(54, 109, 181, 0.5);
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  font-size: 1rem;
  color: ${theme.dark};
  padding: 0 1rem;
  background-color: ${theme.input};
  max-width: 200px;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Submit = styled.button`
  border-style: none;
  padding: 0.8rem 3rem;
  font-size: 1.2rem;
  border-radius: 10px;
  background-color: #366db5;
  color: white;
  margin: 1rem 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  &:hover {
    cursor: pointer;
    background-color: #2d529c;
  }
`;

export const LoginTitle = styled.h2`
  color: ${theme.secondary};
`;

export const Forgot = styled.p`
  color: ${theme.primary};
  text-decoration: underline;
  max-width: 300px;

  &:hover {
    cursor: pointer;
  }
`;

export const ForgotText = styled.p`
  max-width: 300px;
  width: 95%;
  text-align: center;
  color: #db2121;
`;

// Home Styling

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 40%;
  justify-content: center;
  align-items: center;
  background-color: ${theme.light};
  padding: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  border: 1px solid ${theme.input};
`;

export const Widget = styled.div`
  background-color: ${theme.light};
  width: 60%;
  margin: 1rem auto;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1rem;
  border-left: 8px solid ${theme.primary};
  border-radius: 10px;
`;

export const Total = styled.div``;

export const ProfileImage = styled.img`
  border-radius: 100%;
  max-width: 30%;
  border: 5px solid white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-right: 2rem;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileName = styled.p`
  font-size: 1.5rem;
  color: ${theme.primary};
  margin: 0;
`;

export const ProfileDate = styled.p`
  color: ${theme.dark};
`;

export const ProfileTag = styled.p`
  font-style: italic;
  margin-bottom: 0;
  color: ${theme.dark};
`;

export const Logout = styled.button``;

export const WidgetTitle = styled.h3``;

export const Data = styled.div``;

export const Point = styled.div``;

export const Value = styled.p`
  color: ${theme.primary};
  font-size: 1.5rem;
  font-weight: bold;
`;

export const ValueText = styled.p`
  color: ${theme.dark};
  font-style: italic;
`;

// Residual Styling

export const ResidualDisplay = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SummaryTable = styled.table`
  width: 80%;
  background-color: ${theme.light};
  padding: 1rem;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: 1rem;
`;

export const SummaryHead = styled.thead`
  background-color: ${theme.primary};
`;

export const SummaryHeader = styled.th`
  color: ${theme.light};
  padding: 0.5rem;
  width: 30%;
`;

export const SummaryBody = styled.tbody``;

export const SummaryRow = styled.tr``;

export const SummaryData = styled.td`
  text-align: center;
  color: ${theme.dark};
  padding: 0.5rem;
  background-color: ${theme.tint};
  font-size: 1.1rem;
`;

export const SectionTitle = styled.h3`
  color: ${theme.dark};
  margin: 0;
  font-weight: bold;
  font-size: 2rem;
`;

// Resource Stylinh

export const ResourceContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DownloadContainer = styled.div`
  width: 100%;
`;

export const DownloadFolder = styled.div`
  background-color: ${theme.light};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  width: 60%;
  margin: 1rem auto;
`;

export const DownloadItem = styled.a`
  margin: 1rem;
  color: ${theme.primary};
  display: flex;
  align-items: center;

  &:hover {
    color: ${theme.hover};
  }
`;

export const DownloadSectionTitle = styled.h3`
  color: ${theme.dark};
  font-size: 1.3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  background-color: ${theme.light};
  width: 60%;
  margin: 1rem auto;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

// Order Styling

export const OrderContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OrderSelect = styled.select`
  width: 90%;
  height: 3rem;
  font-size: 1.3rem;
  border: 1px solid #366db5;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0 1rem;
  background-color: ${theme.input};
`;

export const Disclaimer = styled.p`
  color: ${theme.dark};
  font-style: italic;
  width: 100%;
  text-align: center;
`;

// Admin Dashboard Styling

export const AdminContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PanelContainer = styled.div`
  width: 80%;
  background-color: ${theme.light};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid ${theme.hover};
`;

export const ControlLink = styled.a`
  color: ${(props) => (props.active ? theme.light : theme.primary)};
  font-size: 1.3rem;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  border: 1px solid ${theme.hover};
  background-color: ${(props) => (props.active ? theme.primary : theme.light)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  &:hover {
    background-color: ${theme.primary};
    color: ${theme.light};
    cursor: pointer;
  }
`;

export const ScreenContainer = styled.div`
  width: 80%;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.light};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid ${theme.hover};
`;

export const AdminForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
`;

export const AdminInput = styled.input`
  border: 1px solid #366db5;
  border-radius: 5px;
  height: 2rem;
  width: 90%;
  font-size: 1rem;
  margin-top: 1rem;
  color: ${theme.dark};
  padding: 0 1rem;
  background-color: ${theme.input};
`;

export const AdminText = styled.textarea`
  border: 1px solid #366db5;
  border-radius: 5px;
  height: 5rem;
  width: 100%;
  font-size: 1rem;
  margin-top: 1rem;
  color: ${theme.dark};
  padding: 1rem;
  background-color: ${theme.input};
`;

export const AdminLabel = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  width: 90%;
`;

export const AdminUpload = styled.input`
  margin-top: 1rem;
`;

export const AdminSubmit = styled.button``;

export const AdminSelect = styled.select`
  border: 1px solid #366db5;
  border-radius: 5px;
  height: 2rem;
  width: 90%;
  font-size: 1rem;
  margin-top: 1rem;
  color: ${theme.dark};
  padding: 0 1rem;
  background-color: ${theme.input};
`;

export const PageContainer = styled.section`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PageContent = styled.div`
  width: 95%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
`;

export const GoBackLink = styled(Link)`
  color: #3d3d3d;
  text-decoration: none;
  width: 95%;
  max-width: 400px;
  margin: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

export const BackText = styled.p`
  text-align: left;
  color: gray;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;
