import React, { useState } from "react";
import { AnimatedOpacity } from "../../../helpers/reusables";

import {
  Form,
  Label,
  TextArea,
  SubmitButton,
  CheckboxGroup,
  Checkbox,
  SectionTitle,
} from "../../ContactForm/Styles/ContactForm";

import {
  Disclaimer,
  OrderContainer,
  OrderSelect,
  ValueText,
} from "../Styles/AgentDashboard";

const OrderOptions = [
  { key: "Select Item", value: "" },
  { key: "Merchant Paper", value: "paper" },
  { key: "Business Cards", value: "cards" },
  { key: "Other", value: "other" },
];

const CardQuantityOptions = [
  { key: "Select Quantity", value: "" },
  { key: "100", value: "100" },
  { key: "250", value: "250" },
  { key: "500", value: "500" },
  { key: "1000", value: "1000" },
];

const PaperOptions = [
  { key: "Select Size", value: "" },
  { key: "3 X 150' Paper", value: "3x150Paper" },
  { key: "2 1/4 x 80' Thermal", value: "2.25x80Thermal" },
  { key: "2 1/4 x 16' Thermal - Coreless", value: "2.25x16Thermal" },
  { key: "3 1/8 x 90' Thermal", value: "31/8x90Thermal" },
  { key: "3 1/8 x 230' Thermal", value: "31/8x230Thermal" },
  { key: "2 1/4 x 50' Thermal", value: "21/4x50Thermal" },
];

const PaperQuantityOptions = [
  { key: "Select Quantity", value: "" },
  { key: "10", value: "10" },
  { key: "25", value: "25" },
  { key: "50", value: "50" },
  { key: "100", value: "100" },
];

function Order() {
  const [item, setItem] = useState("");
  const [cardQuantity, setCardQuantity] = useState("");
  const [paperSize, setPaperSize] = useState("");
  const [paperQuantity, setPaperQuantity] = useState("");

  return (
    <AnimatedOpacity delay={0.2}>
      <OrderContainer>
        <Form>
          <Label>
            Select Item:
            <OrderSelect value={item} onChange={(e) => setItem(e.target.value)}>
              {OrderOptions.map((option, i) => {
                return (
                  <option key={i} value={option.value}>
                    {option.key}
                  </option>
                );
              })}
            </OrderSelect>
          </Label>
          {item === "cards" ? (
            <Label>
              Quantity:
              <OrderSelect
                value={cardQuantity}
                onChange={(e) => setCardQuantity(e.target.value)}
              >
                {CardQuantityOptions.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.key}
                    </option>
                  );
                })}
              </OrderSelect>
            </Label>
          ) : null}
          {item === "paper" ? (
            <Label>
              Paper Size:
              <OrderSelect
                value={paperSize}
                onChange={(e) => setPaperSize(e.target.value)}
              >
                {PaperOptions.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.key}
                    </option>
                  );
                })}
              </OrderSelect>
            </Label>
          ) : null}
          {item === "paper" && paperSize ? (
            <Label>
              Quantity:
              <OrderSelect
                value={paperQuantity}
                onChange={(e) => setPaperQuantity(e.target.value)}
              >
                {PaperQuantityOptions.map((option, i) => {
                  return (
                    <option key={i} value={option.value}>
                      {option.key}
                    </option>
                  );
                })}
              </OrderSelect>
            </Label>
          ) : null}
          {(item === "cards" && cardQuantity) ||
          (item === "paper" && paperSize && paperQuantity) ||
          item === "other" ? (
            <>
              <CheckboxGroup>
                <SectionTitle>Payment Method:</SectionTitle>
                <Label htmlFor="swipesave">
                  <Checkbox type="checkbox" name="swipesave" id="swipesave" />
                  Residual
                </Label>
                <Label htmlFor="competitor">
                  <Checkbox type="checkbox" name="competitor" id="competitor" />
                  Credit / Debit Card
                </Label>
                <Label htmlFor="competitor">
                  <Checkbox type="checkbox" name="competitor" id="competitor" />
                  Check
                </Label>
              </CheckboxGroup>
              <Label>
                Additional Notes / Special Instructions / Request
                <TextArea></TextArea>
              </Label>
              <Disclaimer>
                Invoice will be sent to your registered agent email within
                24hrs.
                <br />
                Please respond to email within 48hrs to confirm order.
              </Disclaimer>
              <SubmitButton type="submit">Submit</SubmitButton>
            </>
          ) : null}
        </Form>
      </OrderContainer>
    </AnimatedOpacity>
  );
}

export default Order;
