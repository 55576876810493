import React from "react";
import FullLengthHero from "../FullLengthHero";

import Header from "../Header";
import Hero from "../Hero/Hero";
import ProductSelector from "../ProductSelector";

import { ImQuotesRight } from "react-icons/im";
import CallToAction from "../CallToAction";
// import Testimonials from "../Testimonials";
import Reviews from "../Reviews";
import Footer from "../Footer";
import Faq from "../Faq";
import Terminals from "../Terminals";
import SwipeHero from "../SwipeHero/";
import WhySwipe from "../WhySwipe/WhySwipe";

let quote =
  "IF THERE IS ONE THING WE KNOW, IT’S THAT BUSINESS OWNERS DESERVE BETTER. WE'RE HERE TO MAKE SERIOUS CHANGE.";
let author = "Jaice de Celis, Founder & CEO";

function Home() {
  return (
    <>
      <Header />
      {/* <Hero /> */}

      <SwipeHero />
      <WhySwipe />

      <ProductSelector />
      <FullLengthHero Icon={ImQuotesRight} title={quote} tagline={author} />
      <Reviews />
      <CallToAction dark={true} />
      <Faq />
      <Terminals />
      <Footer />
    </>
  );
}

export default Home;
