import React from "react";

import { DisplayContainer, Column, Title } from "./Styles/ServiceDisplay";

import "./Styles/ServiceDisplay.css";

import Traditional from "../../images/svgs/Traditional";
import Mobile from "../../images/svgs/Mobile";
import Wallet from "../../images/svgs/Wallet";

function ServiceDisplay() {
  return (
    <>
      <Title className="paddingTop">Most Popular Programs</Title>
      <DisplayContainer>
        <Column>
          <Wallet className="cardSvg" />
          <Title>
            <span className="accent">All New</span>
            <br />
            Swipe38
          </Title>
        </Column>
        <Column>
          <Traditional className="cardSvg" />
          <Title>
            Traditional
            <br />
            Processing
          </Title>
        </Column>
        <Column>
          <Mobile className="cardSvg" />
          <Title>
            Mobile / Wireless
            <br />
            Processing
          </Title>
        </Column>
      </DisplayContainer>
    </>
  );
}

export default ServiceDisplay;
