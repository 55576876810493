import React from "react";

import { AiOutlineUserAdd, AiOutlineLogin } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";

import styles from "./Styles/ProductSelector.module.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { AnimatedOpacity } from "../../helpers/reusables";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

function ProductSelector({ history }) {
  const cards = [
    {
      icon: AiOutlineUserAdd,
      body: "New Merchants",
      tag: "If our rates aren't the best, we’ll cut you a check for $100.",
      button: "Get a Quote",
      link: "/request-quote",
    },
    {
      icon: BiSupport,
      body: "Existing Merchants",
      tag: "Swipe Save Merchants Save Between %15-25 Monthly*",
      button: "Get Help",
      link: "/contact",
    },
    {
      icon: AiOutlineLogin,
      body: "Agent Login",
      tag: "Swipe Save Merchants Save Between %15-25 Monthly*",
      button: "Login",
      link: "/dashboard",
    },
  ];

  return (
    <section className={styles.productSelectorContainer}>
      {cards.map((card) => {
        return (
          <Card
            icon={card.icon}
            body={card.body}
            tag={card.tag}
            button={card.button}
            link={card.link}
            history={history}
          />
        );
      })}
    </section>
  );
}

export default withRouter(ProductSelector);

const Card = ({ icon, body, tag, button, link, history }) => {
  let Icon = icon;

  return (
    <AnimatedOpacity delay={0.5} className={styles.cardContainer}>
      <div className={styles.iconContainer}>
        <Icon size={100} className={styles.icon} />
      </div>
      <p className={styles.textContainer}>
        <span className={styles.textDecorated}>{body}</span>
      </p>
      <div className={styles.buttonContainer}>
        <motion.button
          onClick={() => history.push(link)}
          whileTap={{ scale: 0.9 }}
          className={styles.cardBtn}
        >
          <Link className={styles.buttonLink} to={link}>
            {button}
          </Link>
        </motion.button>
      </div>
    </AnimatedOpacity>
  );
};
