import React from "react";
import NewLead from "./components/NewLead";
import ViewLeads from "./components/ViewLeads";

function CRMControl({ screen, ...restProps }) {
  switch (screen) {
    case "newlead":
      return <NewLead {...restProps} />;
    case "viewleads":
      return <ViewLeads {...restProps} />;
    default:
      return <NewLead {...restProps} />;
  }
}

export default CRMControl;
