import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
  }
`;

export const Terminal = styled.div`
  width: 95%;
  max-width: 200px;

  @media screen and (max-width: 820px) {
    flex-wrap: wrap;
  }
`;

export const Image = styled.img`
  max-width: 90%;
`;

export const Caption = styled.p`
  width: 90%;
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem auto;
`;

export const TerminalsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
