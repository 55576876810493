import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  CTAContainer,
  Info,
  TextContainer,
  Title,
  Button,
} from "./Styles/CallToAction";

import styles from "./Styles/cta.module.css";

function CallToAction({ dark, link, button, hideButton }) {
  button = button ? button : "Start Now";
  link = link ? link : "/request-quote";

  return (
    <CTAContainer dark={dark}>
      <Container>
        {!hideButton ? (
          <Button dark={dark}>
            <Link className={styles.ctaBtn} to={link}>
              {button}
            </Link>
          </Button>
        ) : null}
        <TextContainer>
          <Title>Get Started Now!</Title>
          <Info>
            There is no better time to save!
            <br />
            We've helped our merchants save more than{" "}
            <strong>$1,000,000</strong>
          </Info>
        </TextContainer>
      </Container>
    </CTAContainer>
  );
}

export default CallToAction;
