import React, { useContext, useEffect, useState } from "react";

import {
  LoginContainer,
  LoginLogo,
  LoginForm,
  Input,
  Label,
  Submit,
  LoginTitle,
  Forgot,
  ForgotText,
  PageContainer,
  PageContent,
  BackText,
} from "../Styles/AgentDashboard";

import logo from "../../../images/logo-long.png";
import { useForm } from "react-hook-form";

import fire from "../../../helpers/firebase";

import { AppContext } from "../../../helpers/Context";

import { IoIosArrowRoundBack } from "react-icons/io";

import { default as LoginSVG } from "../../../images/svgs/Login";
import { withRouter } from "react-router";

function Login({ setLoggedIn, history }) {
  const [forgot, setForgot] = useState(false);
  const { register, handleSubmit } = useForm();

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const context = useContext(AppContext);

  const handleLogin = (data) => {
    clearErrors();
    fire
      .auth()
      .signInWithEmailAndPassword(data.emailAddress, data.password)
      .then((res) => {
        context.setUser(res.user.uid);
      })
      .catch((err) => {
        switch (err.code) {
          case "auth/invalid-email":
          case "auth/user-disabled":
          case "auth/user-not-found":
            setEmailError(err.message);
            break;
          case "auth/wrong-password":
            setPasswordError(err.message);
            break;
          default:
            break;
        }
      });
  };

  const clearInputs = () => {
    setEmail("");
    setPassword("");
  };

  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
  };

  const authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        clearInputs();
        setUser(user);
        setLoggedIn(true);
      } else {
        setUser("");
      }
    });
  };

  useEffect(() => {
    authListener();
  }, []);

  return (
    <PageContainer>
      <PageContent>
        <LoginContainer>
          <BackText onClick={() => history.push("/")}>
            <IoIosArrowRoundBack /> Return to Home
          </BackText>
          <LoginLogo src={logo} />
          <LoginForm onSubmit={handleSubmit(handleLogin)}>
            <Label htmlFor="emailAddress">
              <Input
                name="emailAddress"
                id="emailAddress"
                onChange={(e) => setEmail(e.target.value)}
                ref={register}
                value={email}
                placeholder={"Email"}
              />
            </Label>
            <p>{emailError}</p>
            <Label htmlFor="password">
              <Input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                id="password"
                ref={register}
                value={password}
                placeholder={"Password"}
              />
            </Label>
            <p>{passwordError}</p>
            <Submit type="submit">Login</Submit>
            <Forgot onClick={() => setForgot(true)}>
              Forgot Your Password?
            </Forgot>
            {forgot ? (
              <ForgotText>
                Oh no! <br /> Please contact your team lead to change your
                password.
              </ForgotText>
            ) : null}
          </LoginForm>
        </LoginContainer>
        <LoginSVG />
      </PageContent>
    </PageContainer>
  );
}

export default withRouter(Login);
