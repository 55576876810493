import styled from "styled-components";

import theme from "../../../config/theme";

export const DisplayContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  width: 95%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 3rem;
  background-color: ${theme.light};
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  padding: 2rem 0;
  min-height: 200px;
`;

export const Title = styled.h3`
  color: ${theme.dark};
  font-size: 1.5rem;
  text-align: center;
`;

export const Info = styled.p`
  width: 90%;
  margin: 0 auto;
  text-align: center;
`;
