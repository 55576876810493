import React, { useEffect, useState } from "react";
import fire from "../../../../../helpers/firebase";

import {
  FilterSelection,
  Input,
  Label,
  LeadButton,
  LeadsContainer,
  SearchContainer,
  SearchSubmit,
  TryAgain,
} from "../Styles/CRM";

import LeadDisplay from "./LeadDisplay";
import { useForm } from "react-hook-form";

const ViewLeads = () => {
  let db = fire.firestore();
  const { register, handleSubmit } = useForm();

  const [leads, setLeads] = useState([]);
  const [displayLeads, setDisplayLeads] = useState([]);
  const [leadCount, setLeadCount] = useState(5);
  const [contactFilter, setContactFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [merchantFilter, setMerchantFilter] = useState("");

  async function getAgentLeads() {
    let snapshot = await db
      .collection("leads")
      .doc(fire.auth().currentUser.uid)
      .collection("leadList")
      .get();
    let leadarray = [];
    snapshot.docs.map((doc) => {
      let companyCity = doc.data().lead.companyCity;
      let companyName = doc.data().lead.companyName;
      let companyState = doc.data().lead.companyState;
      let companyStreet = doc.data().lead.companyStreet;
      let companyZip = doc.data().lead.companyZip;
      let currentFees = doc.data().lead.currentFees;
      let email = doc.data().lead.email;
      let firstName = doc.data().lead.firstName;
      let lastName = doc.data().lead.lastName;
      let monthlyRev = doc.data().lead.monthlyRev;
      let notes = doc.data().lead.notes;
      let phoneNumber = doc.data().lead.phoneNumber;
      let dateCreated = doc.data().lead.dateCreated;
      let companyRole = doc.data().lead.companyRole;
      let id = doc.id;
      leadarray.push({
        id,
        companyCity,
        companyName,
        companyState,
        companyStreet,
        companyZip,
        currentFees,
        email,
        firstName,
        lastName,
        monthlyRev,
        notes,
        phoneNumber,
        dateCreated,
        companyRole,
      });
    });
    setLeads(leadarray);
  }

  useEffect(() => {
    if (leads.length < 1) {
      getAgentLeads();
    } else if (leads.length >= 1) {
      if (displayLeads.length < 1 && !merchantFilter) {
        setDisplayLeads(leads);
      }
    }
  });

  const handleSort = (data) => {
    let filter = [];
    if (data.merchantFilter) {
      let filteredData = [];
      let currentLeads = filter.length >= 1 ? filter : leads;
      for (let i = 0; i < currentLeads.length; i++) {
        let companyName = currentLeads[i].companyName.toString();
        let filterString = data.merchantFilter.toString();
        if (companyName.toLowerCase().includes(filterString.toLowerCase())) {
          filteredData.push(currentLeads[i]);
          filter = filteredData;
        }
      }
    }
    if (data.cityFilter) {
      let filteredData = [];
      let currentLeads = filter.length >= 1 ? filter : leads;
      for (let i = 0; i < currentLeads.length; i++) {
        let city = currentLeads[i].companyCity.toString();
        let filterString = data.cityFilter.toString();
        if (city.toLowerCase().includes(filterString.toLowerCase())) {
          filteredData.push(currentLeads[i]);
          filter = filteredData;
        }
      }
    }
    if (data.contactFilter) {
      let filteredData = [];
      let currentLeads = filter.length >= 1 ? filter : leads;
      for (let i = 0; i < currentLeads.length; i++) {
        let contactName =
          currentLeads[i].firstName.toString() + " " + currentLeads[i].lastName;
        let filterString = data.contactFilter.toString();
        if (contactName.toLowerCase().includes(filterString.toLowerCase())) {
          filteredData.push(currentLeads[i]);
          filter = filteredData;
        }
      }
    }
    setDisplayLeads(filter);
  };

  return (
    <LeadsContainer>
      <FilterSelection>
        <SearchContainer onSubmit={handleSubmit(handleSort)}>
          <Label htmlFor="merchantFilter">
            Merchant Name:
            <Input
              value={merchantFilter}
              id="merchantFilter"
              name="merchantFilter"
              onChange={(e) => setMerchantFilter(e.target.value)}
              ref={register}
            />
          </Label>
          <Label htmlFor="contactFilter">
            Contact Name:
            <Input
              value={contactFilter}
              id="contactFilter"
              name="contactFilter"
              onChange={(e) => setContactFilter(e.target.value)}
              ref={register}
            />
          </Label>
          <Label htmlFor="cityFilter">
            City:
            <Input
              value={cityFilter}
              id="cityFilter"
              name="cityFilter"
              onChange={(e) => setCityFilter(e.target.value)}
              ref={register}
            />
          </Label>
          <Label>
            Search Leads:
            <SearchSubmit type="submit">Submit</SearchSubmit>
          </Label>
        </SearchContainer>
      </FilterSelection>
      {displayLeads.map((lead, i) => {
        if (i < leadCount) {
          return <LeadDisplay lead={lead} />;
        } else {
          return null;
        }
      })}
      {displayLeads.length < 1 ? <TryAgain>No Results Found.</TryAgain> : null}
      {displayLeads.length > leadCount ? (
        <LeadButton onClick={() => setLeadCount((s) => s + 5)}>
          Show More
        </LeadButton>
      ) : null}
    </LeadsContainer>
  );
};

export default ViewLeads;
