import React, { useState } from "react";

import {
  AdminForm,
  AdminInput,
  AdminText,
  AdminLabel,
  Submit,
} from "../../../Styles/AgentDashboard";

import { InputGroup } from "../../../../ContactForm/Styles/ContactForm";
import { useForm } from "react-hook-form";

import fire from "../../../../../helpers/firebase";

function Adduser() {
  const { register, handleSubmit } = useForm();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tag, setTag] = useState("");
  const [bio, setBio] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [dateJoined, setDateJoined] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  let db = fire.firestore();

  const clearInputs = () => {
    setFirstName("");
    setLastName("");
    setTag("");
    setBio("");
    setProfileImage("");
    setDateJoined("");
  };

  const createUser = (data) => {
    console.log(data);
    clearInputs();
    if (data.username && data.password) {
      fire
        .auth()
        .createUserWithEmailAndPassword(data.username, data.password)
        .then((cred) => {
          return db.collection("users").doc(cred.user.uid).set({
            firstName: data.firstName,
            lastName: data.lastName,
            tag: data.tag,
            bio: data.bio,
            profileImage: data.profileImage,
            dateJoined: data.dateJoined,
          });
        });
    }
  };

  return (
    <AdminForm onSubmit={handleSubmit(createUser)}>
      <InputGroup>
        <AdminLabel htmlFor="username">
          Username:
          <AdminInput
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
            id="username"
            ref={register}
          />
        </AdminLabel>
        <AdminLabel htmlFor="password">
          Password:
          <AdminInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            id="password"
            ref={register}
          />
        </AdminLabel>
      </InputGroup>
      <InputGroup>
        <AdminLabel htmlFor="firstName">
          First Name:
          <AdminInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name="firstName"
            id="firstName"
            ref={register}
          />
        </AdminLabel>
        <AdminLabel htmlFor="lastName">
          Last Name:
          <AdminInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name="lastName"
            id="lastName"
            ref={register}
          />
        </AdminLabel>
      </InputGroup>
      <InputGroup>
        <AdminLabel htmlFor="dateJoined">
          Date Joined:
          <AdminInput
            value={dateJoined}
            onChange={(e) => setDateJoined(e.target.value)}
            name="dateJoined"
            id="dateJoined"
            ref={register}
          />
        </AdminLabel>
        <AdminLabel htmlFor="tag">
          Tagline:
          <AdminInput
            value={tag}
            onChange={(e) => setTag(e.target.value)}
            name="tag"
            id="tag"
            ref={register}
          />
        </AdminLabel>
      </InputGroup>
      <AdminLabel htmlFor="bio">
        Bio:
        <AdminText
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          name="bio"
          id="bio"
          ref={register}
        />
      </AdminLabel>
      <AdminLabel htmlFor="profileIamge">
        Profile Image URL:
        <AdminInput
          value={profileImage}
          onChange={(e) => setProfileImage(e.target.value)}
          name="profileImage"
          id="profileImage"
          ref={register}
        />
      </AdminLabel>
      <Submit type="submit">Create</Submit>
    </AdminForm>
  );
}

export default Adduser;
