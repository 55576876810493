import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { AnimatedOpacity } from "../../helpers/reusables";
import HeroImage from "../../images/svgs/HeroImage";

import styles from "./Styles/Hero.module.css";

function Hero() {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.heroContainer}
    >
      <motion.svg
        className={styles.blob}
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
        height={1200}
        initial={{ rotate: 30 }}
      >
        <path
          fill="#366DB5"
          d="M43.7,-54.8C55.8,-42,64.2,-27.4,66.2,-12.2C68.2,2.9,63.8,18.6,56.9,35C50,51.4,40.6,68.6,26.6,74.2C12.5,79.9,-6.2,74.1,-20.3,64.9C-34.4,55.8,-44,43.4,-49.4,30.3C-54.7,17.3,-55.8,3.6,-51.1,-6.6C-46.4,-16.8,-35.9,-23.6,-26.5,-36.8C-17,-50.1,-8.5,-69.9,3.7,-74.3C15.8,-78.6,31.6,-67.6,43.7,-54.8Z"
          transform="translate(100 100)"
        />
      </motion.svg>
      <AnimatedOpacity delay={0.2} className={styles.textContainer}>
        <h1 className={styles.titleText}>Processing that you can trust.</h1>
        <br />
        <h1 className={styles.titleTag}>Your success is our success.</h1>
        <Link to="/request-quote" className={styles.heroButton}>
          Get a Quote
        </Link>
      </AnimatedOpacity>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={styles.svgContainer}
        transition={{ delay: 0.2 }}
      >
        <HeroImage className={styles.svgImage} />
      </motion.div>
    </motion.section>
  );
}

export default Hero;
