import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={831}
      height={729.989}
      viewBox="0 0 831 729.989"
      {...props}
    >
      <path d="M723.976 703.572v-72.34s28.192 51.286 0 72.34z" fill="#f1f1f1" />
      <path
        d="M725.718 703.559l-53.29-48.921s56.845 13.915 53.29 48.92zM106.46 703.572v-72.34s-28.193 51.286 0 72.34z"
        fill="#f1f1f1"
      />
      <path
        d="M104.718 703.559l53.29-48.921s-56.846 13.915-53.29 48.92z"
        fill="#f1f1f1"
      />
      <path
        d="M590.756 167.048h-3.863V61.24A61.24 61.24 0 00525.654 0h-224.17a61.24 61.24 0 00-61.24 61.24v580.475a61.24 61.24 0 0061.24 61.24h224.17a61.24 61.24 0 0061.24-61.24v-399.35h3.862z"
        fill="#3f3d56"
      />
      <path
        d="M528.125 15.932h-29.262a21.728 21.728 0 01-20.116 29.934H350.322a21.728 21.728 0 01-20.117-29.934h-27.33a45.733 45.733 0 00-45.733 45.733V641.29a45.733 45.733 0 0045.733 45.733h225.25a45.733 45.733 0 0045.733-45.733V61.665a45.733 45.733 0 00-45.733-45.733z"
        fill="#fff"
      />
      <path
        d="M522.483 677.07H308.517c-24.585 0-44.586-17.853-44.586-39.796v-28.81c0-17.49 15.929-31.72 35.508-31.72H531.56c19.58 0 35.508 14.23 35.508 31.72v28.81c0 21.943-20.001 39.796-44.586 39.796z"
        fill="#e5e5e5"
      />
      <path
        d="M331.01 192.845h-47.728a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225z"
        fill="#356eb5"
      />
      <path
        d="M403.246 192.845h-47.728a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.728a3.229 3.229 0 013.224 3.225v47.727a3.229 3.229 0 01-3.224 3.225zM475.482 192.845h-47.728a3.229 3.229 0 01-3.224-3.225v-47.727a3.229 3.229 0 013.224-3.225h47.728a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225zM547.718 192.845h-47.727a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225zM330.839 281.205H283.11a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.728a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225zM403.075 281.205h-47.728a3.229 3.229 0 01-3.224-3.225v-47.727a3.229 3.229 0 013.224-3.225h47.728a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225z"
        fill="#e5e5e5"
      />
      <path
        d="M475.311 281.205h-47.727a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225zM547.547 281.205H499.82a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225v47.727a3.229 3.229 0 01-3.225 3.225z"
        fill="#356eb5"
      />
      <path
        d="M330.668 369.566H282.94a3.229 3.229 0 01-3.224-3.225v-47.728a3.229 3.229 0 013.224-3.224h47.728a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM402.904 369.566h-47.727a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.727a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM475.14 369.566h-47.727a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.727a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM547.377 369.566h-47.728a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.728a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM330.497 457.926H282.77a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225V454.7a3.229 3.229 0 01-3.225 3.225zM402.734 457.926h-47.728a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.727a3.229 3.229 0 013.225 3.225V454.7a3.229 3.229 0 01-3.224 3.225zM474.97 457.926h-47.728a3.229 3.229 0 01-3.225-3.225v-47.727a3.229 3.229 0 013.225-3.225h47.728a3.229 3.229 0 013.225 3.225V454.7a3.229 3.229 0 01-3.225 3.225zM547.206 457.926h-47.728a3.229 3.229 0 01-3.224-3.225v-47.727a3.229 3.229 0 013.224-3.225h47.728a3.229 3.229 0 013.225 3.225V454.7a3.229 3.229 0 01-3.225 3.225z"
        fill="#e5e5e5"
      />
      <path
        d="M331.01 655.286h-47.728a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.727a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM403.246 655.286h-47.728a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.728a3.229 3.229 0 013.224 3.224v47.728a3.229 3.229 0 01-3.224 3.225zM475.482 655.286h-47.728a3.229 3.229 0 01-3.224-3.225v-47.728a3.229 3.229 0 013.224-3.224h47.728a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225zM547.718 655.286h-47.727a3.229 3.229 0 01-3.225-3.225v-47.728a3.229 3.229 0 013.225-3.224h47.727a3.229 3.229 0 013.225 3.224v47.728a3.229 3.229 0 01-3.225 3.225z"
        fill="#fff"
      />
      <circle cx={392.111} cy={548.221} r={6.45} fill="#e5e5e5" />
      <circle cx={415.329} cy={548.221} r={6.45} fill="#e5e5e5" />
      <circle cx={438.548} cy={548.221} r={6.45} fill="#e5e5e5" />
      <path d="M830 704H1a1 1 0 010-2h829a1 1 0 010 2z" fill="#cbcbcb" />
      <path
        d="M326.364 507.102a13.05 13.05 0 00-1.76-19.932l12.596-44.63-22.964 7.304-8.44 41.261a13.12 13.12 0 0020.568 15.997z"
        fill="#ffb7b7"
      />
      <path
        d="M332.894 479.747a5.97 5.97 0 01-1.245.105l-22.03-.442a5.84 5.84 0 01-5.66-6.693l22.348-150.99a18.512 18.512 0 0118.257-15.717 18.413 18.413 0 0117.83 22.825l-15.84 64.157-8.983 81.66a5.806 5.806 0 01-4.677 5.095z"
        fill="#3f3d56"
      />
      <path
        fill="#ffb7b7"
        d="M342.105 718.105h12.26l5.833-47.288-18.095.001.002 47.287z"
      />
      <path
        d="M338.978 714.103l24.144-.001h.001a15.387 15.387 0 0115.387 15.386v.5l-39.531.001z"
        fill="#2f2e41"
      />
      <path
        fill="#ffb7b7"
        d="M394.382 714.123l12.183 1.366 11.067-46.343-17.982-2.016-5.268 46.993z"
      />
      <path
        d="M391.72 709.797l23.994 2.69a15.387 15.387 0 0113.576 17.005l-.055.497-39.285-4.404zM330.599 454.955s-16.808 37.209-1.966 61.377l8.843 188.168h20.303l19.697-152 35 45-21.323 105.028 24.831 1.394L450.476 597.5s-26.498-117.349-35.249-120.174l-5.75-26.826z"
        fill="#2f2e41"
      />
      <path
        d="M323.118 487.622S307.976 505 347.976 511s82-3 83-15-107.858-8.378-107.858-8.378z"
        fill="#cbcbcb"
      />
      <path
        d="M523.635 272.053a11.611 11.611 0 01-14.059 10.925l-15.12 21.804-15.491-5.956 21.784-30.492a11.674 11.674 0 0122.886 3.719z"
        fill="#ffb7b7"
      />
      <path
        d="M395.764 300.107l-21.378-9.671-27.39 1.958S313.977 331 326.977 368l-8 129c51 14 74.183-12.258 111-1l-11.372-143.37z"
        fill="#3f3d56"
      />
      <path
        d="M381.476 309.5l14.288-9.393s19.846-3 27.78 4.696c0 0 26.926 2.254 33.429 8.976l7.16 1.614 23.787-30.802 14.556 7.909-21 57-62.872 3.13z"
        fill="#3f3d56"
      />
      <circle
        cx={554.72}
        cy={334.983}
        r={33.745}
        transform="rotate(-28.663 296.109 653.56)"
        fill="#ffb7b7"
      />
      <path
        d="M341.033 223.595c6.023-10.833 13.55-17.848 25.943-17.595s23.113-.237 28.689 10.834a18.207 18.207 0 002.143-8.98 10.283 10.283 0 014.54 10.345 7.51 7.51 0 003.707-6.165c4.136 1.377 5.211 7.205 3.119 11.03s-6.198 6.034-10.136 7.903-2.12 11.755-4.69 15.275c-1.796 2.456-2.616 5.467-3.826 8.258a.54.54 0 01-.245.256c-2.772 1.546-4.522 4.308-4.033 7.445a1.213 1.213 0 01-.04.71c-3.51 6.613-7.299 13.532-13.661 17.478a22.654 22.654 0 01-17.019 2.63c-6.503-1.554-12.126-5.866-16.18-11.184s-6.662-11.602-8.621-17.996c-1.739-5.671-3.003-11.735-1.624-17.504s6.047-11.112 11.962-11.558"
        fill="#2f2e41"
      />
    </svg>
  );
}

export default SvgComponent;
