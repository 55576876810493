import styled from "styled-components";
import theme from "../../../../../config/theme";

export const CRMContainer = styled.section`
  box-sizing: border-box;
`;

export const CRMNav = styled.ul`
  width: 95%;
  max-width: 1300px;
  background-color: ${theme.light};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  border-radius: 10px;
`;

export const CRMNavLink = styled.li`
  color: ${(props) => (props.active ? theme.light : theme.primary)};
  font-size: 1.3rem;
  margin: 0.5rem;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10px;
  border: 1px solid ${theme.hover};
  background-color: ${(props) => (props.active ? theme.primary : theme.light)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;

  &:hover {
    background-color: ${theme.primary};
    color: ${theme.light};
    cursor: pointer;
  }
`;

export const CRMScreen = styled.div`
  width: 95%;

  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// New LEad Styling

export const NewLeadContainer = styled.div`
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
`;

export const NewLeadForm = styled.form`
  width: 95%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10;
  text-align: center;
  width: 100%;
  justify-content: center;
`;

export const Input = styled.input`
  border: 1px solid #366db5;
  border-radius: 5px;
  min-height: 2rem;
  flex: 1;
  margin: 1rem 0.3rem;
  display: flex;
  font-size: 1rem;
  color: ${theme.dark};
  padding: 0 1rem;
  background-color: ${theme.input};
`;

export const TextArea = styled.textarea`
  border: 1px solid #366db5;
  border-radius: 5px;
  min-height: 10rem;
  flex: 1;
  margin: 1rem 0.3rem;
  display: flex;
  font-size: 1rem;
  color: ${theme.dark};
  padding: 1rem;
  background-color: ${theme.input};
`;

// View Lead Styling

export const LeadsContainer = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  flex-direction: column;
`;

export const LeadContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  width: 90%;
  border-radius: 10px;
  padding: 1rem 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 1rem 0;
  background-color: #ffffff;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const LeadTitle = styled.p`
  font-weight: "bold";
  font-size: 1.7rem;
  margin: 0;
  color: ${theme.primary};
`;

export const LeadName = styled.p`
  font-size: 1.2rem;
`;

export const LeadInfo = styled.p`
  color: black;
`;

export const LeadButton = styled.button`
  border-style: none;
  padding: 0.8rem 3rem;
  font-size: 1.2rem;
  border-radius: 10px;
  background-color: #366db5;
  color: white;
  margin: 1rem 0.2rem;
  width: max-content;

  &:hover {
    cursor: pointer;
    background-color: #2d529c;
  }
`;

export const LeadLabel = styled.label`
  display: flex;
  flex-direction: column;
  color: ${theme.secondary};
`;

export const LeadOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FilterSelection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 1rem 1rem;
  border-radius: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
`;

export const SearchContainer = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SearchSubmit = styled.button`
  border-style: none;
  font-size: 1rem;
  border-radius: 10px;
  background-color: #366db5;
  color: white;
  min-height: 2rem;
  margin: 1rem 0.3rem;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #2d529c;
  }
`;

export const TryAgain = styled.p``;

//Edit Lead Styling

export const EditLeadForm = styled(NewLeadForm)``;
