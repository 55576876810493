import React, { useState } from "react";

import logo from "../../images/logo-long.png";

import {
  AiFillHome,
  AiOutlineCloseCircle,
  AiFillInfoCircle,
} from "react-icons/ai";
import { BsTools, BsFillBriefcaseFill, BsFillChatFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";

import styles from "./Styles/Header.module.css";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, withRouter } from "react-router-dom";

const links = [
  {
    title: "Home",
    to: "/",
    url: "",
    icon: AiFillHome,
  },
  {
    title: "Services",
    to: "/services",
    url: "",
    icon: AiFillInfoCircle,
  },
  {
    title: "About",
    to: "/about",
    url: "",
    icon: BsTools,
  },
  {
    title: "Contact",
    to: "/contact",
    url: "",
    icon: BsFillChatFill,
  },
  {
    title: "Careers",
    to: "/careers",
    url: "",
    icon: BsFillBriefcaseFill,
  },
  {
    title: "Get a Quote",
    to: "/request-quote",
    url: "",
    icon: BsFillBriefcaseFill,
    cta: true,
  },
];

function Header({ history }) {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/dashboard" ? (
        <div className={styles.controlsContainer}>
          <div className={styles.controls}>
            <Link className={styles.controlLink} to="/dashboard">
              Agent Login
            </Link>
          </div>
        </div>
      ) : null}
      <header className={styles.headerContainer}>
        <img
          onClick={() => history.push("/")}
          className={styles.logoImage}
          src={logo}
          alt="Digging up savings one statement at a time"
        />
        <ul className={styles.navList}>
          {links.map((link) => {
            if (link.cta) {
              return (
                <motion.div
                  onClick={() => history.push("/request-quote")}
                  className={styles.ctaNav}
                  whileTap={{ scale: 0.9 }}
                >
                  <Link to={link.to}>{link.title}</Link>
                </motion.div>
              );
            } else {
              return (
                <Link className={link.cta ? styles.ctaNav : null} to={link.to}>
                  {link.title}
                </Link>
              );
            }
          })}
        </ul>
        <Hamburger links={links} />
      </header>
    </>
  );
}

export default withRouter(Header);

const Hamburger = ({ links }) => {
  const [showMenu, setShowMenu] = useState(false);

  const menuVariants = {
    open: {
      x: 0,
      transition: {
        type: "timing",
      },
    },
    closed: {
      x: 1000,
    },
  };

  return (
    <>
      <GiHamburgerMenu
        onClick={() => setShowMenu((s) => !s)}
        className={styles.hamburgerIcon}
        size={40}
      />
      <AnimatePresence>
        {showMenu ? (
          <motion.div
            exit={"closed"}
            className={styles.popMenu}
            initial={"closed"}
            animate={showMenu ? "open" : "closed"}
            variants={menuVariants}
          >
            <AiOutlineCloseCircle
              size={40}
              onClick={() => setShowMenu((s) => !s)}
              className={styles.closeIcon}
            />
            {links.map((link, i) => {
              let Icon = link.icon;

              if (!link.cta)
                return (
                  <Link key={i} to={link.to} className={styles.burgerItem}>
                    <Icon className={styles.burgerIcon} />
                    {link.title}
                  </Link>
                );
            })}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};
