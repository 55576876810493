import React from "react";
import { AnimatedOpacity } from "../../helpers/reusables";
import Footer from "../Footer";
import Header from "../Header";

import styles from "./Styles/swipe38.module.css";

const Swipe38 = () => {
  return (
    <>
      <Header />
      <AnimatedOpacity>
        <section className={styles.container}>
          <div className={styles.whatis}>
            <h1>What is Swipe 38?</h1>
            <p>
              We spent countless hours, negotiating with more than 60 individual
              providers and listening to more than 200 offers to secure what we
              believe are the absolute best processing rates you can find.{" "}
            </p>
          </div>
        </section>
      </AnimatedOpacity>
      <Footer />
    </>
  );
};

export default Swipe38;
