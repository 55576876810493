import React, { useEffect, useState } from "react";

import Footer from "../Footer";
import Header from "../Header";

import { getEmployeeProfile } from "../../helpers/helpers";

import { AiFillPhone, AiFillMail, AiFillLinkedin } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { FaQuora } from "react-icons/fa";

import {
  Profile,
  ProfileImage,
  Name,
  Title,
  Contact,
  Bio,
  Tag,
  BioText,
  Socials,
  ContactInfo,
  Link,
  Divider,
} from "./Styles/EmployeeProfile";

import "./Styles/EmployeeProfile.css";

function EmployeeProfile({ match }) {
  const [employeeInfo, setEmployeeInfo] = useState({});

  useEffect(() => {
    if (match.params.name && Object.entries(employeeInfo).length === 0) {
      setEmployeeInfo(getEmployeeProfile(match.params.name));
    }
  });

  return (
    <>
      <Header />
      <Profile>
        <ProfileImage src={employeeInfo.profileImage} />
        <Name>
          {employeeInfo.firstName} {employeeInfo.lastName}
        </Name>
        <Title>{employeeInfo.title}</Title>
        <Contact>
          <ContactInfo>
            <AiFillPhone className="profile-icons" />
            {employeeInfo.phoneNumber}
          </ContactInfo>
          <ContactInfo>
            <AiFillMail className="profile-icons" />
            {employeeInfo.emailAddress}
          </ContactInfo>
          <ContactInfo>
            <BiWorld className="profile-icons" />
            {employeeInfo.location}
          </ContactInfo>
        </Contact>
        <Bio>
          <Tag>{employeeInfo.tag}</Tag>
          <BioText>{employeeInfo.bio}</BioText>
        </Bio>
        <Divider />
        <Socials>
          <Link target="_blank" href={employeeInfo.linkedin}>
            <AiFillLinkedin className="social-icon" />
          </Link>
          <Link target="_blank" href={employeeInfo.quora}>
            <FaQuora className="social-icon" />
          </Link>
        </Socials>
      </Profile>
      <Footer />
    </>
  );
}

export default EmployeeProfile;
