import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BiWorld, BiCurrentLocation } from "react-icons/bi";
import fire from "../../../../../helpers/firebase";
import { formatMoney, formatPhone } from "../../../../../helpers/helpers";
import { Submit } from "../../../Styles/AgentDashboard";
import {
  EditLeadForm,
  Input,
  InputGroup,
  Label,
  LeadButton,
  LeadContainer,
  LeadInfo,
  LeadLabel,
  LeadName,
  LeadOptions,
  LeadTitle,
  LeftColumn,
  RightColumn,
  TextArea,
} from "../Styles/CRM";

const LeadDisplay = ({ lead }) => {
  const { register, handleSubmit, watch, errors } = useForm();

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyStreet, setCompanyStreet] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [companyZip, setCompanyZip] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [monthlyRev, setMonthlyRev] = useState("");
  const [currentFees, setCurrentFees] = useState("");
  const [notes, setNotes] = useState([]);

  let db = fire.firestore();

  async function getNotes() {
    console.log("hello");
    let noteArray = [];
    let snapshot = await db
      .collection("leads")
      .doc(fire.auth().currentUser.uid)
      .collection("leadList")
      .doc(lead.id)
      .collection("notes")
      .get();
    snapshot.docs.map((note) => {
      noteArray.push(note.data());
    });
    setNotes(noteArray);
  }

  async function handleNotes() {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    let seconds = today.getSeconds();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "-" + dd + "-" + yyyy + ":" + seconds;

    db.collection("leads")
      .doc(fire.auth().currentUser.uid)
      .collection("leadList")
      .doc(lead.id)
      .collection("notes")
      .add({ key: today, value: "test2" });
  }

  const clearInputs = () => {
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setCompanyStreet("");
    setCompanyState("");
    setCompanyCity("");
    setCompanyZip("");
    setEmail("");
    setPhoneNum("");
    setMonthlyRev("");
    setCurrentFees("");
    setNotes("");
    setCompanyRole("");
  };

  async function handleEdit(data) {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = mm + "-" + dd + "-" + yyyy;
    let leadBody = {
      companyCity: data.companyCity,
      companyName: data.companyName,
      companyRole: data.companyRole,
      companyState: data.companyState,
      companyStreet: data.companyStreet,
      companyZip: data.companyZip,
      currentFees: data.currentFees,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      monthlyRev: data.monthlyRev,
      notes: [{ key: today, value: data.notes }],
      phoneNumber: data.phoneNumber,
    };
    let editRef = db
      .collection("leads")
      .doc(fire.auth().currentUser.uid)
      .collection("leadList")
      .doc(lead.id);
    editRef.update(
      {
        lead: { ...leadBody, dateEdited: today },
      },
      { merge: true }
    );
    clearInputs();
  }

  useEffect(() => {
    // if (notes.length < 1) {
    //   getNotes();
    // }
  });

  return (
    <>
      <LeadContainer>
        <LeftColumn>
          <LeadTitle>{lead.companyName}</LeadTitle>
          <LeadName>
            {lead.firstName + " " + lead.lastName}
            {lead.companyRole ? " - " + lead.companyRole : ""}
          </LeadName>
          <LeadInfo>
            <AiOutlineMail />
            {lead.email}
          </LeadInfo>
          <LeadInfo>
            <AiOutlinePhone />
            {`${formatPhone(lead.phoneNumber)}`}
          </LeadInfo>
          {open ? (
            <LeadInfo>
              <BiCurrentLocation />
              {lead.companyStreet}
            </LeadInfo>
          ) : null}
          <LeadInfo>
            <BiWorld />
            {lead.companyCity +
              ", " +
              lead.companyState +
              (open ? " " + lead.companyZip : "")}
          </LeadInfo>
        </LeftColumn>
        <RightColumn>
          {open ? (
            <LeadLabel>
              Monthly Revenue:{" "}
              <LeadInfo>{formatMoney(lead.monthlyRev)}</LeadInfo>
            </LeadLabel>
          ) : null}
          {open ? (
            <LeadLabel>
              Current Fees: <LeadInfo>{formatMoney(lead.currentFees)}</LeadInfo>
            </LeadLabel>
          ) : null}
          <LeadLabel>
            Most Recent Notes:{" "}
            <LeadInfo>
              {notes && notes.length > 0 ? notes[0].value : "N/A"}
            </LeadInfo>
          </LeadLabel>
          <LeadLabel>
            Lead Creation Date: <LeadInfo>{lead.dateCreated}</LeadInfo>
          </LeadLabel>
          <LeadOptions>
            <LeadButton onClick={() => setOpen((s) => !s)}>
              Toggle Details
            </LeadButton>
            <LeadButton onClick={() => setEdit((s) => !s)}>
              Edit Lead
            </LeadButton>
            <LeadButton onClick={() => handleNotes()}>Add Notes</LeadButton>
          </LeadOptions>
        </RightColumn>
      </LeadContainer>
      {edit ? (
        <EditLeadForm onSubmit={handleSubmit(handleEdit)}>
          <InputGroup>
            <Label htmlFor="firstName">
              First Name
              <Input
                ref={register}
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Label>
            <Label htmlFor="lastName">
              Last Name
              <Input
                ref={register}
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="companyRole">
              Company Role
              <Input
                ref={register}
                name="companyRole"
                id="companyRole"
                value={companyRole}
                onChange={(e) => setCompanyRole(e.target.value)}
              />
            </Label>
            <Label htmlFor="companyName">
              Company Name
              <Input
                ref={register}
                name="companyName"
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="companyStreet">
              Company Street
              <Input
                ref={register}
                name="companyStreet"
                id="companyStreet"
                value={companyStreet}
                onChange={(e) => setCompanyStreet(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="companyCity">
              Company City
              <Input
                ref={register}
                name="companyCity"
                id="companyCity"
                value={companyCity}
                onChange={(e) => setCompanyCity(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="companyState">
              Company State
              <Input
                ref={register}
                name="companyState"
                id="companyState"
                value={companyState}
                onChange={(e) => setCompanyState(e.target.value)}
              />
            </Label>
            <Label htmlFor="companyZip">
              Company Zip
              <Input
                ref={register}
                name="companyZip"
                id="companyZip"
                value={companyZip}
                onChange={(e) => setCompanyZip(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="email">
              Email
              <Input
                ref={register}
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Label>
            <Label htmlFor="phoneNumber">
              Phone Number
              <Input
                ref={register}
                name="phoneNumber"
                id="phoneNumber"
                value={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="monthlyRev">
              Monthly Revenue
              <Input
                ref={register}
                name="monthlyRev"
                id="monthlyRev"
                value={monthlyRev}
                onChange={(e) => setMonthlyRev(e.target.value)}
              />
            </Label>
            <Label htmlFor="currentFees">
              Current Fees:
              <Input
                ref={register}
                name="currentFees"
                id="currentFees"
                value={currentFees}
                onChange={(e) => setCurrentFees(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="notes">
              Notes:
              <TextArea
                ref={register}
                value={notes && notes.length > 0 ? notes[0].value : "N/A"}
                id="notes"
                name="notes"
                onChange={(e) => setNotes(e.target.value)}
              />
            </Label>
          </InputGroup>
          <InputGroup>
            <Submit type="submit">Submit Edits</Submit>
          </InputGroup>
        </EditLeadForm>
      ) : null}
    </>
  );
};

export default LeadDisplay;
