import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createGlobalStyle } from "styled-components";

import Home from "./components/Home";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Careers from "./components/Careers/";

import EmployeeProfile from "./components/EmployeeProfile";
import AgentDashboard from "./components/AgentDashboard";

// import Swipe38 from "./components/Swipe38";

import "./global.css";

import Context from "./helpers/Context";
import QuoteTool from "./components/QuoteTool/QuoteTool";
import Swipe38 from "./components/Swipe38/Swipe38";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300&display=swap');

  html,
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    width: 100vw;
    background-color: #FFF;
    -webkit-appearance: none;
    overflow-x: hidden;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(250,250,250,1) 100%);
  }`;

function App() {
  return (
    <Context>
      <Router>
        <GlobalStyle />
        <Switch>
          <Route path="/services" component={Services} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/careers" component={Careers} />
          <Route
            path="/profile/:name"
            render={(props) => <EmployeeProfile {...props} />}
          />
          <Route
            path="/dashboard"
            render={(props) => <AgentDashboard {...props} />}
          />
          <Route path="/request-quote" render={QuoteTool} />
          <Route path="/swipe38" component={Swipe38} />
          <Route path="/" component={Home} />
        </Switch>
        <WarningMessage />
      </Router>
    </Context>
  );
}

export default App;

const WarningMessage = () => {
  const [showWarning, setShowWarning] = useState(true);

  if (!showWarning) {
    return null;
  }

  return (
    <div className="warningMessage">
      <p onClick={() => setShowWarning(false)} className="warningClose">
        X
      </p>
      <p className="warningTitle">Site Under Construction</p>
      <p className="warningBody">
        Some features may currently be unavailable. Please check back soon if
        you encounter any issues.
      </p>
    </div>
  );
};
