import React from "react";

import {
  HeroContainer,
  HeroImage,
  HeroTitle,
  TagLine,
} from "./Styles/FullLengthHero";

import "./Styles/FullLengthHero.css";

function FullLengthHero({ image, title, tagline, Icon, light }) {
  return (
    <HeroContainer>
      <HeroImage image={image}>
        <Icon className="hero-icon" />
        <HeroTitle light={light}>{title}</HeroTitle>
        <TagLine>{tagline}</TagLine>
      </HeroImage>
    </HeroContainer>
  );
}

export default FullLengthHero;
