import styled from "styled-components";

import theme from "../../../config/theme";

import { AiFillStar } from "react-icons/ai";

export const ReviewContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  background-color: ${theme.light};
  padding: 1rem 0rem 2rem 0rem;
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ReviewsTitle = styled.h2`
  text-align: center;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 2rem;
`;

export const Review = styled.div`
  width: 50%;
  margin: 0rem 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  padding: 1rem 2rem;
  border-radius: 10px;
  min-height: 300px;
  background-color: ${theme.light};

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    width: 75%;
    max-width: 500px;
    margin: 1rem 0;
    min-height: unset;
    padding: 2rem;
  }
`;

export const Title = styled.h3`
  font-size: 1.3rem;
`;

export const Name = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
`;

export const Info = styled.p`
  font-style: italic;
`;

export const TextContainer = styled.div``;

export const StarContainer = styled.div``;

export const Star = styled(AiFillStar)`
  font-size: 1.5rem;
  color: ${theme.primary};
`;
