import React, { useState } from "react";

import {
  AdminContainer,
  ControlLink,
  PanelContainer,
  ScreenContainer,
} from "../../Styles/AgentDashboard";

import { AiOutlineUserAdd, AiFillPlusCircle } from "react-icons/ai";
import { IoLogoUsd } from "react-icons/io";
import AdminControl from "./AdminControl";
import { AnimatedOpacity } from "../../../../helpers/reusables";

function Admin() {
  const [screen, setScreen] = useState("recordpayment");

  return (
    <AnimatedOpacity delay={0.2}>
      <AdminContainer>
        <PanelContainer>
          <ControlLink
            active={screen === "recordpayment"}
            onClick={() => setScreen("recordpayment")}
          >
            <IoLogoUsd /> Record Payment
          </ControlLink>
          <ControlLink
            active={screen === "newmerchant"}
            onClick={() => setScreen("newmerchant")}
          >
            <AiFillPlusCircle /> New Merchant
          </ControlLink>
          <ControlLink
            active={screen === "adduser"}
            onClick={() => setScreen("adduser")}
          >
            <AiOutlineUserAdd /> New Agent
          </ControlLink>
        </PanelContainer>
        <ScreenContainer>
          <AdminControl screen={screen} />
        </ScreenContainer>
      </AdminContainer>
    </AnimatedOpacity>
  );
}

export default Admin;
