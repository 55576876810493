import { motion } from "framer-motion";
import React from "react";

export const AnimatedOpacity = ({ children, delay, ...props }) => {
  return (
    <motion.div
      style={{ width: "100%", height: "100%" }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: delay }}
      {...props}
    >
      {children}
    </motion.div>
  );
};
