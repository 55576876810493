import React from "react";
import Admin from "./Admin";
import Home from "./Home";
import Order from "./Order";
import Residual from "./Residual";
import Resources from "./Resources";
import CRM from "./CRM";

function DisplayControl({ screen, ...restProps }) {
  switch (screen) {
    case "home":
      return <Home {...restProps} />;
    case "order":
      return <Order {...restProps} />;
    case "residual":
      return <Residual {...restProps} />;
    case "resources":
      return <Resources {...restProps} />;
    case "admin":
      return <Admin {...restProps} />;
    case "crm":
      return <CRM {...restProps} />;
    default:
      return <Home {...restProps} />;
  }
}

export default DisplayControl;
