import styled from "styled-components";

import theme from "../../../config/theme";

export const CTAContainer = styled.section`
  background-color: ${(props) => (props.dark ? theme.primary : theme.light)};
  color: ${(props) => (props.dark ? theme.light : theme.dark)};
  width: 100%;
  padding: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 720px) {
    flex-direction: column-reverse;
  }
`;

export const Button = styled.button`
  font-size: 1.2rem;
  background-color: ${(props) => (props.dark ? theme.light : theme.primary)};
  border-radius: 10px;
  border-style: none;
  color: ${(props) => (props.dark ? theme.dark : theme.light)};
  padding: 0.8rem;
  width: 30%;
  max-width: 250px;

  @media screen and (max-width: 435px) {
    width: 50%;
  }
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  margin: 0;

  @media screen and (max-width: 720px) {
    text-align: center;
  }
`;

export const Info = styled.p`
  width: 100%;
  font-size: 1.3rem;
  font-weight: 300;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  @media screen and (max-width: 720px) {
    text-align: center;
    margin-left: 0;
  }
`;
