import styled from "styled-components";

import theme from "../../../config/theme";

export const Title = styled.h2``;

export const Container = styled.section``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 800px;
  margin: 3rem auto;
  background-color: ${theme.light};
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.5rem;
`;

export const Input = styled.input`
  border: 1px solid #366db5;
  border-radius: 5px;
  height: 2rem;
  width: 80%;
  margin-top: 1rem;
  padding: 0 1rem;
  background-color: ${theme.input};
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;

export const TextArea = styled.textarea`
  width: 80%;
  border: 1px solid #366db5;
  border-radius: 5px;
  min-height: 5rem;
  margin: 1rem 0;
  padding: 1rem;
  resize: none;
  background-color: ${theme.input};
`;

export const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  flex-direction: column;
`;

export const SubmitButton = styled.button`
  border-style: none;
  padding: 0.8rem 3rem;
  font-size: 1.2rem;
  border-radius: 10px;
  background-color: #366db5;
  color: white;
  margin: 1rem 0;

  &:hover {
    cursor: pointer;
    background-color: #2d529c;
  }
`;
export const Checkbox = styled.input`
  width: 100%;
`;

export const SectionTitle = styled.p`
  color: ${theme.dark};
  width: 100%;
  text-align: center;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  border: 1px solid #366db5;
  border-radius: 5px;
  padding: 1rem;
  margin-top: 2rem;
  background-color: ${theme.input};

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;

export const Upload = styled.input`
  margin: 3rem 0;
`;
