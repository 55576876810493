import React from "react";
import { AnimatedOpacity } from "../../helpers/reusables";
import CallToAction from "../CallToAction";
import Expectations from "../Expectations";
import Footer from "../Footer";
import Header from "../Header";
import ServiceDisplay from "../ServiceDisplay";
import Terminals from "../Terminals";

function Services() {
  return (
    <>
      <Header />
      <AnimatedOpacity>
        <ServiceDisplay />
        <Expectations />
        <CallToAction dark={true} hideButton={false} />
        <Terminals />
      </AnimatedOpacity>
      <Footer />
    </>
  );
}

export default Services;
